var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "destroy-on-close": true,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close,
        open: _vm.open,
      },
    },
    [
      _c("MyForm", {
        ref: "editForm",
        attrs: {
          "edit-cfg": _vm.editForm,
          "edit-data": _vm.editData,
          "edit-rules": _vm.editRules,
          that: _vm.that,
          labelMaxWidth: "135px",
        },
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { staticClass: "mr10", on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitBnt } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }