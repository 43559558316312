import Layout from '@/layout'
import {USER_TYPE} from '@/const/sys'
// 企业端 培训归档
const trainningArchiveRouter = {
  path: '/train-archive',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '',
    icon: null,
    roles: [USER_TYPE.organization.val],
  },
  // alwaysShow: true,
  children: [
    {
      path: '',
      component: () => import('@/views/company/train-archive'),
      name: 'train-archive',
      meta: {title: '培训归档', icon: null},
    },
    {
      path: 'details',
      component: () => import('@/views/company/train-archive/detail'),
      name: 'details',
      hidden: true,
    },
  ],
}

export default trainningArchiveRouter
