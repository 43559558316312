<!-- 搜索表单 -->
<template>
  <el-form
    ref="editForm"
    v-loading="formLoading"
    :class="[customClass?customClass:(doubleRowStyle?'double-row-style':'')]"
    :label-width="doubleRowStyle?labelWidth:labelMaxWidth"
    :model="editData"
    :rules="editRules"
    :size="size"
    :validate-on-rule-change="false"
  >

    <el-form-item
      v-for="(item,index) in editCfg"
      v-show="item.isShow === null || item.isShow === undefined ? true : item.isShow(that,editData[item.prop])"
      :key="item.label"
      :class="[index===(editCfg.length-1)?(doubleRowStyle?'margin-style-three':'margin-style-two'):'margin-style']"
      :label="item.label"
      :prop="item.prop"
    >
      <!-- txt -->
      <template v-if="item.type === 'txt'">
        <div :style="{width:item.width?item.width:'520px'}" style="line-height: 1.2">{{ editData[item.prop] }}</div>
      </template>
      <!-- 输入框 -->
      <template v-if="item.type === 'input'">
        <el-input
          v-model="editData[item.prop]"
          :disabled="item.disabled && item.disabled(editData)"
          :maxlength="item.maxLength"
          :placeholder="item.placeholder"
          :style="{width:item.width?item.width:'520px'}"
          clearable
          @change="item.change && item.change(editData[item.prop])"
          @input.native="item.input && item.input(editData[item.prop])"
        />
      </template>
      <!-- 地图选择框 -->
      <el-input
        v-if="item.type==='inputAndButton'"
        v-model="editData[item.prop]"
        :clearable="item.isClearable"
        :disabled="item.disabled && item.disabled(editData)"
        :maxlength="item.maxlength"
        :placeholder="item.placeholder"
        :rows="item.row"
        :show-word-limit="item.isShowWord"
        :style="{width:item.width?item.width:'520px'}"
        @change="item.change && item.change(editData[item.prop])"
      >
        <el-button
          slot="append"
          style=" color: #FFFFFF;border-color: #0067C0 ;background-color: #0067C0 ;height: 32px;"
          type="primary"
          @click="item.buttonClick && item.buttonClick(editData[item.prop])"
        >{{
            item.buttonText || '确定'
          }}
        </el-button>
      </el-input>
      <!-- 密码输入框 -->
      <template v-if="item.type === 'password'">
        <el-input
          v-model="editData[item.prop]"
          :disabled="item.disabled && item.disabled(editData)"
          :maxlength="item.maxLength"
          :placeholder="item.placeholder"
          :style="{width:item.width?item.width:'520px'}"
          clearable
          type="password"
          @change="item.change && item.change(editData[item.prop])"
          @input.native="item.input && item.input(editData[item.prop])"
        />
      </template>
      <!-- 文本域 -->
      <el-input
        v-if="item.type==='textarea'"
        :id="item.id ||''"
        v-model="editData[item.prop]"
        :clearable="item.isClearable"
        :disabled="item.disabled && item.disabled(editData)"
        :maxlength="item.maxlength"
        :placeholder="item.placeholder"
        :rows="item.row"
        :show-word-limit="item.isShowWord"
        :style="{width:item.width?item.width:'520px'}"
        type="textarea"
        @change="item.change && item.change(editData[item.prop])"
      />
      <!-- 地图选择框 -->
      <el-input
        v-if="item.type==='map'"
        v-model="editData[item.prop]"
        :clearable="item.isClearable"
        :disabled="item.disabled && item.disabled(editData)"
        :maxlength="item.maxlength"
        :placeholder="item.placeholder"
        :rows="item.row"
        :show-word-limit="item.isShowWord"
        :style="{width:item.width?item.width:'520px'}"
        @change="item.change && item.change(editData[item.prop])"
      >
        <el-button slot="append" icon="el-icon-search" @click="showMap(item)"/>
      </el-input>
      <!-- 下拉框 -->
      <el-select
        v-if="item.type==='select'"
        v-model="editData[item.prop]"
        :allow-create="item.isAllowCreate"
        :clearable="item.isClearable"
        :collapse-tags="item.isCollapseTags"
        :disabled="item.disabled && item.disabled(editData)"
        :filterable="item.filterable"
        :loading="item.loading"
        :loading-text="item.loadingText"
        :multiple="item.isMultiple"
        :placeholder="item.placeholder"
        :remote="item.isRemote"
        :remote-method="item.remoteMethod"
        :reserve-keyword="item.reserveKeyword"
        :style="{width:item.width?item.width:'520px'}"
        @change="item.change &&item.change(that,editData[item.prop])"
      >
        <div v-if="item.prop==='community'">
          <el-option v-for="op in communityData" :key="op.id" :label="op.parkName" :value="op.parkId"/>
        </div>
        <div v-else-if="item.mySelf">
          <el-option v-for="op in item.options" :key="op.value" :label="op.label" :value="op.value">
            <span style="float: left">{{ op.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ op.mySelf }}</span>
          </el-option>
        </div>
        <div v-else>
          <template v-for="op in item.options">
            <el-option :key="op.value" :label="op.label" :value="op.value"/>
          </template>
        </div>
        <el-pagination
          v-show="item.isPage"
          :current-page="item.currentPage?item.currentPage:1"
          :page-size="item.pageSize?item.pageSize:10"
          :total="item.totalPage?item.totalPage:0"
          layout="prev, pager, next"
          small
          @current-change="handleCurrentChange"
        />
      </el-select>
      <!-- 级联选择 -->
      <el-cascader
        v-if="item.type==='cascader'"
        v-model="editData[item.prop]"
        :clearable="item.isClearable"
        :disabled="item.disabled && item.disabled(editData)"
        :options="item.options"
        :placeholder="item.placeholder"
        :props="item.props"
        :show-all-levels="item.showAllLevels"
        :style="{width:item.width?item.width:'520px'}"
        @change="item.change &&item.change(that,editData[item.prop])"
      />
      <!-- 关键字搜索 -->
      <el-autocomplete
        v-if="item.type==='autocomplete'"
        v-model="editData[item.prop]"
        :fetch-suggestions="item.querySearchAsync"
        :placeholder="item.placeholder"
        :style="{width:item.width?item.width:'520px'}"
        @select="item.handleSelect"
      />
      <!-- 单选 -->
      <el-radio-group
        v-if="item.type==='radio'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        :style="{width:item.width?item.width:'520px'}"
        @change="item.change && item.change(editData[item.prop])"
      >
        <el-radio v-for="ra in item.radios" :key="ra.value" :label="ra.value">{{ ra.label }}</el-radio>
      </el-radio-group>
      <!-- 单选按钮 -->
      <el-radio-group
        v-if="item.type==='radioButton'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        @change="item.change && item.change(editData[item.prop])"
      >
        <el-radio-button v-for="ra in item.radios" :key="ra.value" :label="ra.value">{{ ra.label }}</el-radio-button>
      </el-radio-group>
      <!-- 计数器 -->
      <el-input-number
        v-if="item.type==='inputNumber'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        :label="item.label"
        :max="item.max"
        :min="item.min"
        @change="item.change && item.change(editData[item.prop])"
      />
      <!-- 单个复选框 -->
      <el-checkbox
        v-if="item.type==='checkboxFlag'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        :style="{width:item.width?item.width:'520px'}"
        @change="item.change && item.change(editData[item.prop])"
      >
        {{ item.text }}
      </el-checkbox>
      <!-- 复选框 -->
      <el-checkbox-group
        v-if="item.type==='checkbox'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        :style="{width:item.width?item.width:'520px'}"
        @change="item.change && item.change(editData[item.prop])"
      >
        <el-checkbox v-for="(ch,checkBoxIndex) in item.checkBoxes" :key="checkBoxIndex" :label="ch.value">{{
            ch.label
          }}
        </el-checkbox>
      </el-checkbox-group>
      <!-- 日期 -->
      <el-date-picker
        v-if="item.type==='date'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        :placeholder="item.placeholder"
        :style="{width:item.width?item.width:'520px'}"
        :value-format="item.format"
        @change="item.change && item.change(editData[item.prop])"
      />
      <!-- 年 -->
      <el-date-picker
        v-if="item.type==='year'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        :placeholder="item.placeholder"
        :style="{width:item.width?item.width:'520px'}"
        :type="item.type"
        :value-format="item.format"
        @change="item.change && item.change(editData[item.prop])"
      />
      <!-- 月 -->
      <el-date-picker
        v-if="item.type==='month'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        :placeholder="item.placeholder"
        :style="{width:item.width?item.width:'520px'}"
        :type="item.type"
        @change="item.change && item.change(editData[item.prop])"
      />
      <!-- 区间 -->
      <el-date-picker
        v-if="item.type==='daterange'"
        v-model="editData[item.prop]"
        :disabled="item.disable && item.disable(editData)"
        :end-placeholder="item.endPlaceholder"
        :placeholder="item.placeholder"
        :range-separator="item.separator"
        :start-placeholder="item.startPlaceholder"
        :style="{width:item.width?item.width:'520px'}"
        :type="item.type"
        :value-format="item.format"
        @change="item.change && item.change(editData[item.prop])"
      />
      <!-- 任意时间 -->
      <el-time-picker
        v-if="item.type==='timePicker'"
        v-model="editData[item.prop]"
        :format="item.format"
        :picker-options="item.pickerOptions"
        :placeholder="item.placeholder"
        :style="{width:item.width?item.width:'520px'}"
        :value-format="item.valueFormat"
      />
      <!-- 固定时间段 -->
      <el-time-select
        v-if="item.type==='time'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        :format="item.format"
        :picker-options="item.pickerOptions"
        :placeholder="item.placeholder"
        :style="{width:item.width?item.width:'520px'}"
        @change="item.change && item.change(editData[item.prop])"
      />
      <!-- 日期时间 -->
      <el-date-picker
        v-if="item.type==='dateTime'"
        v-model="editData[item.prop]"
        :default-time="item.default"
        :disabled="item.disable && item.disable(editData[item.prop])"
        :format="item.format"
        :picker-options="item.pickerOptions"
        :placeholder="item.placeholder"
        :style="{width:item.width?item.width:'520px'}"
        :value-format="item.valueFormat"
        type="datetime"
        @change="item.change && item.change(editData[item.prop])"
      />
      <!-- 评分 -->
      <el-rate
        v-if="item.type==='rate'"
        :colors="item.colors"
        :disabled="scoreDisabled"
        :icon-classes="item.iconClasses"
        :show-text="item.showText"
        :texts="item.texts"
        :value="score"
        @change="changeScore"
      />
      <!-- 滑块 -->
      <!-- <el-slider v-if="item.type==='Slider'" v-model="editData[item.prop]"></el-slider> -->
      <!-- 开关 -->
      <el-switch
        v-if="item.type==='switch'"
        v-model="editData[item.prop]"
        :disabled="item.disabled && item.disabled(editData)"
        @change="item.change && item.change(editData[item.prop])"
      />
      <slot v-if="item.type === 'slot'" :name="item.slotName"/>
      <!-- 文件上传 -->
      <template v-if="item.type === 'upload'">
        <my-upload
          ref="myUpload"
          :disabled="item.disabled && item.disabled(editData)"
          :field="item.prop"
          :img-url="editData[item.prop]"
          :request-flag="item.requestFlag"
          :value.sync="editData[item.prop]"
          @validateField="validateField"
        />
      </template>
      <!-- 各种类型文件上传 -->
      <template v-if="item.type === 'MultiTypeUpload'">
        <MyMultiTypeUpload
          ref="myUpload"
          :disabled="item.disabled && item.disabled(editData)"
          :field="item.prop"
          :file-arr="editData[item.prop]"
          :file-path="item.filePath"
          :is-priview="item.isPriview|| false"
          :limit="item.limit"
          :remark="item.remark"
          :request-flag="item.requestFlag"
          :tips="item.tips"
          :value.sync="editData[item.prop]"
          @validateField="validateField"
        />
      </template>
      <!-- 多张图片文件上传 -->
      <template v-if="item.type === 'multipleImgUpload'">
        <my-multiple-img-upload
          :custom-tips="item.customTips"
          :field="item.prop"
          :img-size="item.imgSize||'10'"
          :limit="item.limit"
          :my-file-list="editData[item.prop]"
          :request-flag="item.requestFlag"
          :style="{width:item.width?item.width:'520px'}"
          :upload-path="item.uploadPath"
          :value.sync="editData[item.prop]"
          @validateField="validateField"
        />
      </template>
      <template v-if="item.type === 'wangeditor'">
        <WangEditor
          ref="wangeditor"
          :content.sync="editData[item.prop]"
          :is-clear="wangeditorClear"
          :value="editData[item.prop]"
        />
      </template>
    </el-form-item>
    <!-- 按钮 -->
    <el-form-item
      v-if="formHandler.length"
      :class="[formHandler[0].classStyle?'right':(formHandler.length?'margin-styles':'')]"
      style="padding-bottom: 16px"
    >
      <el-button
        v-for="item in formHandler"
        :key="item.key"
        :loading="item.loading"
        :type="item.type"
        @click="item.handler && item.handler(that)"
      >
        {{ item.label }}
      </el-button>
    </el-form-item>
    <!--    <el-dialog-->
    <!--      :close-on-click-modal="false"-->
    <!--      :title="mapTitle||'获取定位'"-->
    <!--      :visible.sync="isShowMap"-->
    <!--      append-to-body-->
    <!--      width="40%"-->
    <!--    >-->
    <!--      <el-input v-model="searchKey" class="input-with-select" placeholder="请输入关键字" style="padding-bottom: 5px;">-->
    <!--        <el-button slot="append" icon="el-icon-search" @click="searchMap" />-->
    <!--      </el-input>-->
    <!--      当前经纬度为：{{ selPos.join(',') }}-->
    <!--      <gaode-map-->
    <!--        ref="gaodemap"-->
    <!--        :city-name="cityName"-->
    <!--        :default-pos="selPos.join(',')"-->
    <!--        :search-key="searchKey"-->
    <!--        style="padding-top: 5px;"-->
    <!--        @setMarker="setMarker"-->
    <!--      />-->
    <!--      <span slot="footer" class="dialog-footer">-->
    <!--        <el-button-->
    <!--          style="width: 15%"-->
    <!--          type="primary"-->
    <!--          @click="setPositon"-->
    <!--        >确定</el-button>-->
    <!--      </span>-->
    <!--    </el-dialog>-->
  </el-form>
</template>

<script>
import MyUpload from '@/components/MyUpload'
import WangEditor from '@/components/WangEditor'
import MyMultipleImgUpload from '@/components/MyMultipleImgUpload'
import MyMultiTypeUpload from '@/components/MyMultiTypeUpload'

export default {
  name: 'MyForm',
  components: {
    // Treeselect,
    MyUpload,
    WangEditor,
    MyMultipleImgUpload,
    MyMultiTypeUpload,
  },
  props: {
    that: {
      type: Object,
      default: this,
    },
    formLoading: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: '110px', // 超过六个字换行
    },
    labelMaxWidth: {
      type: String,
      default: '130px',
    },
    scoreDisabled: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'medium',
    },
    customClass: {
      type: String,
      default: '',
    },
    editCfg: {
      type: Array,
      default: () => [],
    },
    editData: {
      type: Object,
      default: () => {
      },
    },

    editRules: {
      type: Object,
      default: null,
    },
    // 按钮
    formHandler: {
      type: Array,
      default: () => [],
    },
    // 是否双行展示 !!!注意当前若双行展示表单则需要把按钮放入表单循环内！！！！
    doubleRowStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowMap: false, // 是否显示地图
      mapTitle: '',
      searchKey: '',
      cityName: '',
      selPos: [],
      marker: null,
      selItem: null,
      // that: this,
      // communityData: [],
      score: 5,
      // 清除富文本
      wangeditorClear: false, // true false
    }
  },
  mounted() {
    this.editCfg.forEach(val => {
      // if (val.prop === 'community') {
      // this.getCommunity(val)
      // }
    })
  },
  methods: {
    selectTree(data1) {
      this.$emit('selectTree', data1.id)
    },
    // 重置
    rest() {
      this.$refs.editForm.resetFields()
    },
    changeScore(val) {
      this.score = val
      this.$emit('changeScore', val)
    },
    showMap(item) {
      this.isShowMap = true
      this.mapTitle = item.title
      this.selItem = item
      if (item.searchKey) {
        this.searchKey = item.searchKey
      }
      if (item.cityName) {
        this.cityName = item.cityName
      }
      if (this.selPos.length === 0 && this.editData[item.prop]) {
        this.selPos = this.editData[item.prop].split(',')
      }
    }, searchMap() {
      this.$refs.gaodemap.selPlace(this.searchKey)
    },
    setMarker(lng, lat) {
      this.selPos.length = 0
      this.selPos.push(lng)
      this.selPos.push(lat)
    },
    setPositon() {
      this.isShowMap = false
      this.editData[this.selItem.prop] = this.selPos.join(',')
    },
    // 得到社区数据
    getCommunity(val) {
      getLoadUserInfo().then(data => {
        if (data.guardParkList.length > 0) {
          this.communityData = data.guardParkList
          if (!val.isMultiple) {
            if (val.isShowAllCommunity) {
              if (data.guardParkList.length > 1) {
                const allCommunity = {}
                allCommunity.id = '123'
                allCommunity.parkName = '全部社区'
                const parkIds = this.$route.query.parkId
                allCommunity.parkId = parkIds
                this.communityData.unshift(allCommunity)
              }
            }
            this.editData.community = this.communityData[0].parkId
            this.$emit('getCommunity', data)
          } else {
            this.communityData.forEach((val, index) => {
              this.editData.community.push(val.parkId)
              if (index === this.communityData.length - 1) {
                this.$emit('getCommunity', data)
              }
            })
          }
        } else {
          this.editData.communityIds = '暂无小区'
        }
      })
    },
    // 页码修改
    handleCurrentChange(val) {
      this.$emit('refreshPage', val)
    },
    validateField(field) {
      this.$refs.editForm.validateField(field)
    },
    clearProps() {
      this.$refs.myUpload.forEach(i => {
        if (i.clearFiles) {
          i.clearFiles()
        }
      })
    },
    // getThat(){
    //     this.$emit('that',this)
    // }
  },

}

</script>
<style lang="scss" scoped>
.addTitle {
  background-color: white;
  margin: 0px 12px 0 12px !important;
  border-radius: 2px;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-wrap: wrap;
}

.el-form {
  background-color: white;
  margin: 12px 12px 0 12px;
  border-radius: 2px;
  padding: 16px 16px 0 16px;
}

.el-dialog__body > .el-form {
  background-color: white;
  margin: 6px auto 38px auto !important;
  border-radius: 2px;
  padding: 0 !important;
}

.double-row-style {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1525px) {
  .margin-style-three {
    display: flex;
    width: 50%;
    justify-content: center;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1525px) {
  .margin-style-three {
    display: flex;
    //background-color: red;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }
}

.margin-style {
  margin: 0 auto 32px auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.margin-style-two {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.right {
  text-align: right;
}

.multipleImgUpload {
  text-align: left;
}

.el-input-number--medium {
  width: 520px;
}
</style>

