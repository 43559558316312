<template>
  <div class="question">
    <div class="header">
      <el-button size="mini" type="warning" @click="toBack">退出测试</el-button>
      <span>{{ paperEntity }}</span>
      <div />
    </div>
    <div class="content">
      <div class="left">
        <!-- 单选题 -->
        <div v-if="active <= singleChoiceList.length">
          <div class="first">单选题（共{{
            singleChoiceList.length
          }}题，{{ singleChoiceList.length > 0 ? singleChoiceList[0].point : 0 }}分/题）
          </div>
          <ul>
            <!-- eslint-disable-next-line -->
            <li v-for="(item, index) in singleChoiceList" v-if="active === index + 1" :key="index">
              <div class="second">{{ active }}.{{ item.title }}</div>
              <div v-if="item.titleImg" style="width: 200px;height: 200px;margin-bottom: 30px">
                <img :src="item.titleImg" style="width: 100%;height: 100%;">
              </div>
              <div class="third">
                <el-radio-group v-model="item.own" @change="changeChiose(item, index)">
                  <el-radio
                    v-for="(val, index) in item.answerOptions"
                    :key="index"
                    :disabled="item.own !== null && item.own !== ''"
                    :label="val.option"
                  >{{ val.option }}.{{ val.value }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div v-if="item.seeFlag === true" class="fity">
                <div class="title">答案解析</div>
                <div class="isCorret">
                  <div v-if="item.expectedAnswer === item.own" class="sure">回答正确</div>
                  <div v-else class="error">
                    <span>回答错误</span>
                    <span>正确答案</span>
                    <span>{{ item.expectedAnswer }}</span>
                  </div>
                </div>
                <div class="analysis">{{ item.description }}</div>
              </div>
              <div class="fourth">
                <el-button v-if="active > 1" size="mini" type="primary" @click="backClick">上一题</el-button>
                <el-button size="mini" type="primary" @click="nextClick">下一题</el-button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 判断题 -->
        <div v-else-if="active <= singleChoiceList.length + TrueFalseList.length && active > singleChoiceList.length">
          <div class="first">判断题（共{{ TrueFalseList.length }}题，{{
            TrueFalseList.length > 0 ? TrueFalseList[0].point : 0
          }}分/题）
          </div>
          <ul>
            <!-- eslint-disable-next-line -->
            <li v-for="(item, index) in TrueFalseList" v-if="active === singleChoiceList.length + index + 1"
                :key="index"
            >
              <div class="second">{{ active }}.{{ item.title }}</div>
              <div class="third">
                <el-radio-group
                  v-model="item.own"
                  :disabled="item.own !== null && item.own !== ''"
                  @change="trueFalseChiose(item, index)"
                >
                  <el-radio label="true">正确</el-radio>
                  <el-radio label="false">错误</el-radio>
                </el-radio-group>
              </div>
              <div v-if="item.seeFlag === true" class="fity">
                <div class="title">答案解析</div>
                <div class="isCorret">
                  <div v-if="item.expectedAnswer === item.own" class="sure">回答正确</div>
                  <div v-else class="error">
                    <span>回答错误</span>
                    <span />
                  </div>
                </div>
                <div class="analysis">{{ item.description }}</div>
              </div>
              <div class="fourth">
                <el-button v-if="active > 1" size="mini" type="primary" @click="backClick">上一题</el-button>
                <el-button
                  v-if="active < singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length "
                  size="mini"
                  type="primary"
                  @click="nextClick"
                >下一题
                </el-button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 多选题 -->
        <div
          v-else-if="active <= singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length && active > singleChoiceList.length + TrueFalseList.length"
        >
          <div class="first">多选题（共{{
            MultiChoiceList.length
          }}题，{{ MultiChoiceList.length > 0 ? MultiChoiceList[0].point : 0 }}分/题）
          </div>
          <ul>
            <!-- eslint-disable-next-line -->
            <li v-for="(item, index) in MultiChoiceList"
                v-if="active === TrueFalseList.length + singleChoiceList.length + index + 1"
                :key="index"
            >
              <div class="second">{{ active }}.{{ item.title }}</div>
              <div v-if="item.titleImg" style="width: 200px;height: 200px;margin-bottom: 30px">
                <img :src="item.titleImg" style="width: 100%;height: 100%;">
              </div>
              <div class="third">
                <el-checkbox-group v-model="item.own">
                  <el-checkbox
                    v-for="(val, index) in item.answerOptions"
                    :key="index"
                    :disabled="item.seeFlag === true"
                    :label="val.option"
                  >{{ val.option }}.{{ val.value }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div v-if="item.seeAnalysis === true" class="fity">
                <div class="title">答案解析</div>
                <div class="isCorret">
                  <div v-if="item.expectedAnswer === (item.own).join('#').toUpperCase()" class="sure">回答正确</div>
                  <div v-else class="error">
                    <span>回答错误</span>
                    <span>正确答案</span>
                    <span>{{ (item.expectedAnswer.split('#')).join(',') }}</span>
                  </div>
                </div>
                <div class="analysis">{{ item.description }}</div>
              </div>
              <div class="fourth">
                <el-button v-if="active > 1" size="mini" type="primary" @click="backClick">上一题</el-button>
                <el-button
                  v-if="active < singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length"
                  size="mini"
                  type="primary"
                  @click="nextClick(item, index)"
                >下一题
                </el-button>
                <el-button size="mini" type="primary" @click="sureMulClick(item, index)">查看解析</el-button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 案例题 -->
        <div
          v-else-if="active <= singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length && active > singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length"
        >
          <div class="first">案例题（共{{ exampleList.length }}题，{{
            exampleList.length > 0 ? exampleList[0].point : 0
          }}分/题）
          </div>
          <ul>
            <!-- eslint-disable-next-line -->
            <li v-for="(item, index) in exampleList"
                v-if="active === MultiChoiceList.length + TrueFalseList.length + singleChoiceList.length + index + 1"
                :key="index"
            >
              <div class="second">{{ active }}.{{ item.title }}</div>
              <div v-for="(val, i) in item.children" :key="i" class="third">
                <div class="second">{{ i + 1 }}.{{ val.title }}</div>
                <el-radio-group
                  v-if="val.type === 'SingleChoice'"
                  v-model="val.own"
                  style="margin-bottom: 20px"
                  @change="changeChiose(val, index)"
                >
                  <el-radio
                    v-for="(a, b) in val.answerOptions"
                    :key="b"
                    :disabled="val.own !== null && val.own !== ''"
                    :label="a.option"
                  >{{ a.option }}.{{ a.value }}
                  </el-radio>
                  <div v-if="val.seeFlag === true" class="isCorret">
                    <div class="title">答案解析</div>
                    <div v-if="val.own === val.expectedAnswer" class="sure">回答正确</div>
                    <div v-else class="error">
                      <span>回答错误</span>
                      <span>正确答案</span>
                      <span v-if="val.expectedAnswer === 'true' || val.expectedAnswer === 'false'" />
                      <span v-else>{{ val.expectedAnswer }}</span>
                    </div>
                    <div class="analysis">{{ val.description }}</div>
                  </div>
                </el-radio-group>
                <el-radio-group
                  v-if="val.type === 'TrueFalse'"
                  v-model="val.own"
                  :disabled="val.own !== null && val.own !== ''"
                  style="margin-bottom: 20px"
                  @change="trueFalseChiose(val, index)"
                >
                  <el-radio label="true">正确</el-radio>
                  <el-radio label="false">错误</el-radio>
                  <div v-if="val.seeFlag === true" class="isCorret">
                    <div class="title">答案解析</div>
                    <div v-if="val.own === val.expectedAnswer" class="sure">回答正确</div>
                    <div v-else class="error">
                      <span style="color: #F43114">回答错误</span>
                    </div>
                    <div class="analysis">{{ val.description }}</div>
                  </div>
                </el-radio-group>
                <el-checkbox-group v-if="val.type === 'MultiChoice'" v-model="val.own" style="margin-bottom: 20px">
                  <el-checkbox
                    v-for="(a, b) in val.answerOptions"
                    :key="b"
                    :disabled="val.seeFlag === true"
                    :label="a.option"
                  >{{ a.option }}.{{ a.value }}
                  </el-checkbox>
                  <div v-if="val.seeFlag === true" class="isCorret">
                    <div class="title">答案解析</div>
                    <div v-if="val.expectedAnswer === (val.own).join('#').toUpperCase()" class="sure">回答正确</div>
                    <div v-else class="error">
                      <span class="error">回答错误</span>
                      <span>正确答案</span>
                      <span>{{ (val.expectedAnswer.split('#')).join(',') }}</span>
                    </div>
                    <div class="analysis">{{ val.description }}</div>
                  </div>
                </el-checkbox-group>
                <el-button
                  v-if="val.type === 'MultiChoice'"
                  :disabled="val.seeFlag === true"
                  size="mini"
                  style="margin-bottom: 20px"
                  type="primary"
                  @click="sureMulClick(val, index)"
                >确认答案
                </el-button>
              </div>
            </li>
          </ul>
          <div class="fourth">
            <el-button v-if="active > 1" size="mini" type="primary" @click="backClick">上一题</el-button>
            <el-button
              v-if="active < singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length"
              size="mini"
              type="primary"
              @click="nextClick"
            >下一题
            </el-button>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <div class="time">离考试结束：{{dowmTime}}</div> -->
        <div class="btn">
          <el-button size="mini" type="warning" @click="submitClick">交卷</el-button>
        </div>
        <div class="number">
          <div style="margin-left: 40px ">
            <span style="font-size: 24px">答题卡</span>
            <span
              style="font-size: 14px"
            >(共{{
              singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length
            }}题，总分100分，{{ passScore }}分合格)</span>
          </div>
          <div style="margin-left: 40px;margin-top: 20px ">单选题</div>
          <ul>
            <li
              v-for="(item, index) in singleChoiceList"
              :key="index"
              :class="item.class"
              @click="singleChoiceChange(index)"
            >{{ index + 1 }}
            </li>
          </ul>
          <div style="margin-left: 40px;margin-top: 20px ">判断题</div>
          <ul>
            <li v-for="(item, index) in TrueFalseList" :key="index" :class="item.class" @click="trueFalseChange(index)">
              {{ index + 1 }}
            </li>
          </ul>
          <div style="margin-left: 40px;margin-top: 20px ">多选题</div>
          <ul>
            <li
              v-for="(item, index) in MultiChoiceList"
              :key="index"
              :class="item.class"
              @click="multiChoiceChange(index)"
            >{{ index + 1 }}
            </li>
          </ul>
          <div style="margin-left: 40px;margin-top: 20px ">案例题</div>
          <ul>
            <li v-for="(item, index) in exampleList" :key="index" :class="item.class" @click="exampleChange(index)">
              {{ index + 1 }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { batchSubmitItem, createAnswerSheet } from '@/api/student/exam'
import { QUESTION_TYPE } from '@/const/sys'
import Tools from '@/utils/tools'

export default {
  name: 'ExaminationTestTab',
  props: {
    // bizLine:{
    //   required:true,
    //   type:String
    // }
  },
  data() {
    return {
      // 试卷标题
      paperEntity: '',
      // 试卷编号
      paper_No: null,
      // 当前做到哪个题目
      active: 1,
      // 单选题列表
      singleChoiceList: [],
      // 多选列表
      MultiChoiceList: [],
      // 判断列表
      TrueFalseList: [],
      // 案例题列表
      exampleList: [],
      // 总题数
      total: [],
      // 通过分数
      passScore: null,
      // 新的答题卡id
      NewSheetNo: '',
    }
  },
  mounted() {
    // 初始化答题卡
    const postData = {
      paperNo: this.paperNo,
      stuNo: this.stuNo,
      orgId: this.orgId,
    }
    createAnswerSheet(postData).then(res => {
      if (res.success) {
        this.NewSheetNo = res.data.sheetNo
        if (this.paperInfo) {
          this.total = this.paperInfo
        } else {
          this.total = res.data.paperQuestionInfoList
        }
        this.formatPaperInfo(this.total)
      }
    })
  },
  computed: {
    ...mapState('stuExam', {
      paperNo: state => state.paperNo,
      paperType: state => state.paperType,
      stuNo: state => state.stuNo,
      orgId: state => state.orgId,
      sheetNo: state => state.sheetNo,
      paperInfo: state => state.paperInfo,
      dictKey: state => state.dictKey,
    }),
  },
  created() {
  },
  methods: {
    ...mapMutations('stuExam', [
      'savePaperNo', 'savePaperType', 'saveTextResult', 'savePaperInfo',
    ]),
    // 格式化考卷
    formatPaperInfo(list) {
      list.forEach(item => {
        if (item.questionType === QUESTION_TYPE.SingleChoice.val || item.questionType === QUESTION_TYPE.SingleChoiceWithPic.val) {
          this.passScore = item.passScore
          this.paperEntity = item.paperTitle
          const obj = {}
          // 题号
          obj.no = item.no
          // 题目内容
          obj.title = item.questionTitle
          // 图片
          obj.titleImg = item.titleImg
          // 选项
          obj.answerOptions = eval(item.answerOptions)
          // 解析
          obj.seeFlag = false
          obj.description = item.questionDes
          // 选中的答案
          obj.own = ''
          // 正确答案
          obj.expectedAnswer = item.expectedAnswer
          // 题目类型
          obj.type = item.questionType
          // 题目分数
          obj.point = item.point
          // 题目ID
          obj.questionId = item.questionId
          this.singleChoiceList.push(obj)
        } else if (item.questionType === QUESTION_TYPE.MultiChoice.val || item.questionType === QUESTION_TYPE.MultiChoiceWithPic.val) {
          const obj = {}
          // 题号
          obj.no = item.no
          // 题目内容
          obj.title = item.questionTitle
          // 图片
          obj.titleImg = item.titleImg
          // 选项
          const arr = eval(item.answerOptions)
          arr.map(item => {
            item.flag = false
          })
          obj.answerOptions = arr
          // 解析
          obj.seeFlag = false
          obj.seeAnalysis = false
          obj.description = item.questionDes
          // 选中的答案
          obj.own = []
          // 正确答案
          obj.expectedAnswer = item.expectedAnswer
          // 题目类型
          obj.type = item.questionType
          // 题目分数
          obj.point = item.point
          // 题目ID
          obj.questionId = item.questionId
          this.MultiChoiceList.push(obj)
        } else if (item.questionType === QUESTION_TYPE.TrueFalse.val) {
          const obj = {}
          // 题号
          obj.no = item.no
          // 题目内容
          obj.title = item.questionTitle
          // 选项
          obj.answerOptions = eval(item.answerOptions)
          // 解析
          obj.seeFlag = false
          obj.description = item.questionDes
          // 选中的答案
          obj.own = ''
          // 正确答案
          obj.expectedAnswer = item.expectedAnswer
          // 题目类型
          obj.type = item.questionType
          // 题目分数
          obj.point = item.point
          // 题目ID
          obj.questionId = item.questionId
          this.TrueFalseList.push(obj)
        } else if (item.questionType === QUESTION_TYPE.example.val) {
          const obj = {}
          obj.no = item.no
          obj.title = item.questionTitle
          // 题目分数
          obj.point = item.point
          const itemArr = []
          obj.questionId = item.questionId
          item.child.map(itm => {
            const iobj = {}
            iobj.no = itm.no
            iobj.title = itm.questionTitle
            iobj.expectedAnswer = itm.expectedAnswer
            iobj.own = itm.questionType === QUESTION_TYPE.MultiChoice.val ? [] : ''
            iobj.seeFlag = false
            iobj.seeAnalysis = false
            iobj.questionId = itm.questionId
            // iobj.options = JSON.parse(itm.answerOptions)
            const earr = eval(itm.answerOptions)
            earr.map(im => {
              im.flag = false
            })
            iobj.answerOptions = earr
            iobj.description = itm.questionDes
            iobj.type = itm.questionType
            itemArr.push(iobj)
          })
          obj.children = itemArr
          this.exampleList.push(obj)
          // obj.children = item.child
        }
      })
    },
    // 临时跳转按钮
    toBack() {
      this.$confirm('退出测试将无测试成绩', '提示', {
        confirmButtonText: '确认退出',
        cancelButtonText: '继续答题',
        type: 'warning',
      }).then(() => {
        this.$emit('toList')
      }).catch(() => {
        return false
      })
    },
    // 上一题
    backClick() {
      this.active -= 1
    },
    // 下一题
    nextClick(val, index) {
      // this.active += 1
      if (val.type === QUESTION_TYPE.MultiChoice.val || val.type === QUESTION_TYPE.MultiChoiceWithPic.val) {
        if (val.own.length) {
          val.seeFlag = true
          if (this.active > this.singleChoiceList.length + this.TrueFalseList.length + this.MultiChoiceList.length) {
            this.exampleList[index].class = 'blue'
          } else {
            if (val.own.sort().join('#').toUpperCase() === val.expectedAnswer.toUpperCase()) {
              this.MultiChoiceList[index].class = 'sure'
            } else {
              this.MultiChoiceList[index].class = 'error'
            }
          }
        }
      }
      this.active += 1
    },
    // 单选切换
    singleChoiceChange(index) {
      this.active = index + 1
    },
    // 判断切换
    trueFalseChange(index) {
      this.active = this.singleChoiceList.length + index + 1
    },
    // 多选切换
    multiChoiceChange(index) {
      this.active = this.singleChoiceList.length + this.TrueFalseList.length + index + 1
    },
    // 案例题切换
    exampleChange(index) {
      this.active = this.singleChoiceList.length + this.TrueFalseList.length + this.MultiChoiceList.length + index + 1
    },
    // 单选确定
    changeChiose(val, index) {
      // console.log(val, index)
      val.seeFlag = true
      if (this.active > this.singleChoiceList.length + this.TrueFalseList.length + this.MultiChoiceList.length) {
        this.exampleList[index].class = 'blue'
      } else {
        if (val.own === val.expectedAnswer) {
          this.singleChoiceList[index].class = 'sure'
        } else {
          this.singleChoiceList[index].class = 'error'
        }
      }
    },
    // 判断提交
    trueFalseChiose(val, index) {
      val.seeFlag = true
      if (this.active > this.singleChoiceList.length + this.TrueFalseList.length + this.MultiChoiceList.length) {
        this.exampleList[index].class = 'blue'
      } else {
        if (val.own === val.expectedAnswer) {
          this.TrueFalseList[index].class = 'sure'
        } else {
          this.TrueFalseList[index].class = 'error'
        }
      }
    },
    // 多选提交
    sureMulClick(val, index) {
      val.seeAnalysis = true
      if (this.active > this.singleChoiceList.length + this.TrueFalseList.length + this.MultiChoiceList.length) {
        val.seeFlag = true
      } else {
        val.seeAnalysis = true
      } //  else {
      //   if (val.own.sort().join('#').toUpperCase() === val.expectedAnswer.toUpperCase()) {
      //     this.MultiChoiceList[index].class = 'sure'
      //   } else {
      //     this.MultiChoiceList[index].class = 'error'
      //   }
      // }
    },
    // 整合数据
    batchSubmitItemClick() {
      const arr = []
      this.singleChoiceList.map(item => {
        arr.push({
          questionId: item.questionId,
          answer: item.own,
        })
      })
      this.TrueFalseList.map(item => {
        arr.push({
          questionId: item.questionId,
          answer: item.own,
        })
      })
      this.MultiChoiceList.map(item => {
        arr.push({
          questionId: item.questionId,
          answer: item.own.sort().join('#').toUpperCase(),
        })
      })
      this.exampleList.map(item => {
        item.children.map(val => {
          if (val.type !== 'MultiChoice') {
            arr.push({
              questionId: val.questionId,
              answer: val.own,
            })
          } else {
            arr.push({
              questionId: val.questionId,
              answer: val.own.sort().join('#').toUpperCase(),
            })
          }
        })
      })
      batchSubmitItem({
        questionList: arr,
        paperNo: this.paperNo,
        sheetNo: this.NewSheetNo,
        stuNo: this.stuNo,
        orgNo: this.orgId,
        passScore: this.passScore,
      }).then(res => {
        if (res.success) {
          this.savePaperInfo(this.total)
          this.saveTextResult(res.data)
          this.$emit('toResult')
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              return false
            },
          })
        }
      })
    },
    // 交卷
    submitClick() {
      this.$confirm('您是否确定交卷', '提示', {
        confirmButtonText: '确定交卷',
        cancelButtonText: '继续答题',
        type: 'warning',
      }).then(() => {
        this.batchSubmitItemClick()
        return
      }).catch(() => {
        return false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.question {
  background-color: white;
  margin: 12px 12px 12px 12px;
  border-radius: 2px;
  padding: 16px 60px 16px 60px;
  height: 90vh;

  .header {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    span {
      font-size: 22px;
    }
  }

  .content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 65%;

      .first {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        line-height: 36px;
      }

      .second {
        width: 100%;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 2px;
        word-wrap: break-word;
        word-break: normal;
      }

      .third {
        width: 100%;

        .el-radio-group {
          width: 100%;
          line-height: 30px;
          padding-left: 20px;

          .el-radio {
            width: 100%;
            line-height: 30px;
            font-size: 16px;
            display: block;
            white-space: normal;
          }
        }

        .el-checkbox-group {
          width: 100%;
          line-height: 30px;
          padding-left: 20px;

          .el-checkbox {
            width: 100%;
            line-height: 30px;
            font-size: 16px;
            display: block;
            white-space: normal;
          }
        }
      }

      .fourth {
        margin-top: 30px;
      }

      .fourth > * {
        margin-right: 20px;
      }

      .fity {
        width: 100%;
        border: 1px solid #ccc;
        position: relative;
        margin-top: 20px;
        padding: 10px;

        .title {
          position: absolute;
          top: -10px;
          left: 30px;
          font-size: 16px;
          background-color: #fff;
        }

        .isCorret {
          width: 100%;
          padding: 10px;
          background-color: rgb(242, 242, 242);
          line-height: 36px;
          font-size: 16px;
          margin-top: 10px;

          .sure {
            color: rgb(16, 221, 159);
            padding-left: 20px;
            box-sizing: border-box;
          }

          .error {
            padding-left: 20px;
            box-sizing: border-box;

            span {
              &:first-of-type {
                color: #F43114;
                margin-right: 20px;
              }

              &:last-of-type {
                color: #10DD9F;
                margin-left: 20px;
              }
            }
          }
        }

        .analysis {
          width: 100%;
          font-size: 14px;
          box-sizing: border-box;
          margin-top: 10px;
          line-height: 20px;
          word-wrap: break-word;
          word-break: normal;
          padding-left: 20px;
          box-sizing: border-box;
        }
      }
    }

    .right {
      width: 30%;

      .time {
        font-size: 18px;
      }

      .btn {
        text-align: right;
      }

      .number {
        margin-top: 30px;

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 25px;
            height: 25px;
            font-size: 12px;
            border: 1px solid #cccccc;
            line-height: 25px;
            text-align: center;
            margin: 5px;
            color: #000;
            list-style: none;

            &:hover {
              cursor: pointer;
            }

            &.sure {
              color: #fff;
              background-color: #10dd9f;
            }

            &.error {
              color: #fff;
              background-color: #dd1010;
            }

            &.blue {
              color: #fff;
              background-color: blue;
            }
          }
        }
      }
    }
  }

  .isCorret {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    position: relative;
    line-height: 36px;
    font-size: 16px;
    margin-top: 10px;
    padding: 20px;

    .title {
      position: absolute;
      top: -17px;
      left: 30px;
      font-size: 16px;
    }

    .sure {
      color: rgb(16, 221, 159);
      background-color: rgb(242, 242, 242);
      padding-left: 20px;
      box-sizing: border-box;
    }

    .error {
      box-sizing: border-box;
      background-color: rgb(242, 242, 242);
      padding-left: 20px;

      span {
        &:first-of-type {
          color: #F43114;
          margin-right: 20px;
        }

        &:last-of-type {
          color: #10DD9F;
          margin-left: 20px;
        }
      }
    }

    .analysis {
      width: 100%;
      font-size: 14px;
      box-sizing: border-box;
      margin-top: 10px;
      line-height: 20px;
      word-wrap: break-word;
      word-break: normal;
      padding-left: 20px;
      box-sizing: border-box;
      background-color: #fff;
    }
  }
}
</style>

<style>
.el-message-box__header {
  padding: 6px;
  background-color: #0799C5;

}

.el-message-box__title {
  font-size: 12px;
  color: #fff;
}

.el-message-box__headerbtn {
  position: absolute;
  top: 3px;
}

.el-message-box__headerbtn .el-message-box__close {
  color: #fff;
}

.el-message-box__btns {
  text-align: center;
}
</style>
