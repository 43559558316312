<template>
  <div>
    <div v-if="!noPermission" class="flex flex-row align-center justify-center noData_model">
      <img class="login_img_noData" src="../../assets/images/authLogin_noData.png">
    </div>
  </div>
</template>

<script>
import { setToken, setUserInfo } from '@/utils/auth'
import { LOGIN_TYPE, ORG_USER_TYPE } from '@/const/sys'

export default {
  name: 'Sso',
  components: {},
  data() {
    return {
      imgList: [
        { code: 0, img: 'authLogin_org' },
        { code: 1, img: 'authLogin_stu' },
      ],
      activeName: LOGIN_TYPE.ORGANIZATION.val,
      LOGIN_TYPE,
      powerList: [],
      noPermission: false,
    }
  },
  created() {
    const _id = this.$route.params.id
    this.checkUser(_id)
  },
  methods: {
    checkUser(_id) {
      const _data = {
        serialNo: _id,
      }
      this.$store.dispatch('user/thirdLogin', _data).then(rst => {
        this.noPermission = true
        // 设置免登录标记
        if (rst.thirdUserInfoVoList.length > 0) {
          this.jumpTo(rst.thirdUserInfoVoList[0])
        }
      }).catch(err => {
        this.loading = false
      })
    },
    toNext(item) {
      const obj = this.powerList[item.code]
      this.jumpTo(obj)
    },
    jumpTo(obj) {
      setUserInfo(obj.userInfoVo)
      setToken(obj.userInfoVo.token)
      if (obj.govInfo && (obj.govInfo.orgUserType === ORG_USER_TYPE.gov_park_admin.val || obj.govInfo.orgUserType === ORG_USER_TYPE.gov_province_admin.val)) {
        this.$router.replace('/statistic')
      } else if (obj.orgInfo && obj.orgInfo.orgUserType === ORG_USER_TYPE.admin.val) {
        this.$router.replace('/admin/home')
      } else {
        this.$router.replace('/student/learningCenter/myCourses')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.noData_model {
  width: 100vw;
  height: 100vh;
}

.login_img_noData {
  width: 240px;
  height: 274px;
}

.login_img {
  width: 367px;
  height: 460px;
  cursor: pointer;
}

.select_model {
  height: 100vh;
  margin-left: 22vw;
  margin-right: 22vw;
}

.login-wrap {
  height: 100vh;
  background: url("~@/assets/images/authLogin_bg.jpg");
  background-size: 100% 100%;
}
</style>

