var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c(
        "div",
        {
          staticClass:
            "footer-content flex flex-column align-center justify-center flex-1",
        },
        [
          _c("div", { staticClass: "fs30 pb10" }, [
            _vm._v("应急安全教育专业平台"),
          ]),
          _c("div", { staticClass: "fs14" }, [
            _vm._v("版权所有：应急安全教育专业平台"),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }