<template>
  <div className="app-container">
    <HSearch
      ref="searchForm"
      :search-data="searchData"
      :search-form="searchForm"
      :search-handle="searchHandle"
      :search-rules="searchRules"
      :that="that"
      label-width="80px"
      size="medium"
    />
    <HTable
      :is-handle="true"
      :is-index="true"
      :loading="tableLoading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-page="tablePage"
      :that="that"
    />
  </div>
</template>

<script>
import HSearch from '@/components/MySearch/index.vue'
import HTable from '@/components/MyTable/index.vue'
import {orgTrainPlanHubeiQuery} from '@/api/company/trainningPlan'
import Tools from '@/utils/tools'
import {ORG_USER_TYPE, PLAN_STATES, TRAINNING_STATUS, TRAINNING_TYPE, USER_TYPE} from '@/const/sys'
import {getUserInfo} from '@/utils/auth'
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'OrgTrainingPlan',
  components: {
    HSearch,
    HTable,
  },
  data() {
    const _TrainStatus = Tools.mapToSelctList(TRAINNING_STATUS)
    const _TRAINNING_TYPE = Tools.mapToSelctList(TRAINNING_TYPE)
    if (getUserInfo().userType === USER_TYPE.government.val) {
      _TrainStatus.splice(0, 1)
    }
    return {
      userInfo: {},
      PLAN_STATES,
      USER_TYPE,
      filterCategoryAllArr: [],
      that: this,
      tableLoading: false,
      searchData: {
        trainningSerial: '',
        trainningType: '',
        trainningName: '',
        trainningStatus: '',
        orgName: '',
      },
      categoryObj: {}, // 培训类别对象
      searchForm: [
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'orgName',
          placeholder: '请输入所属企业',
          isClearable: true,
          isShow: () => getUserInfo().userType === USER_TYPE.government.val,
        },
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'trainningSerial',
          placeholder: '请输入计划编号',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'trainningName',
          placeholder: '请输入计划名称',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'trainningType',
          width: '260px',
          placeholder: '请选择培训方式',
          options: _TRAINNING_TYPE,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '所属园区',
          prop: 'parkName',
          isShow: () => getUserInfo().govInfo && getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_province_admin.val,
        },
        {
          label: '所属企业',
          prop: 'orgName',
          isShow: () => getUserInfo().userType === USER_TYPE.government.val,
        },
        {
          label: '培训计划编号',
          prop: 'trainningSerial',
        },
        {
          label: '培训方式',
          prop: 'trainningType',
          formatter: row => Tools.formatDict(TRAINNING_TYPE, row.trainningType),
        },
        // {
        //   label: '培训类别',
        //   prop: 'categoryIdList',
        //   type: 'hover',
        //   width: '220px',
        //   formatter: row => this.fomatCategory(row),
        // },
        {
          label: '培训计划名称',
          prop: 'trainningName',
        },
        {
          label: '培训课程',
          prop: 'courseName',
        },
        {
          label: '培训开始时间',
          prop: 'trainningStartTime',
        },
        {
          label: '培训结束时间',
          prop: 'trainningEndTime',
        },
        {
          label: '授课人',
          prop: 'teacher',
          width: '100px',
        },
        {
          label: '培训人数',
          prop: 'pernum',
          width: '85px',
        },
        {
          label: '状态',
          prop: 'trainningStatus',
          formatter: row => Tools.formatDict(TRAINNING_STATUS, row.trainningStatus),
        },
        {
          label: '操作列',
          type: 'button',
          width: '200px',
          btnList:
            [
              {
                label: '详情',
                type: 'text',
                handle: (that, row) => that.goDetailsPage(row),
              },
            ],
        },
      ],
      tablePage: {
        pageSize: 50,
        total:
          0,
        pageNum:
          1,
        handlePageNumChange:
          (that, val) => {
            this.tablePage.pageNum = val
            this.getTableData()
          },
        handlePageSizeChange:
          (that, val) => {
            this.tablePage.pageSize = val
            this.getTableData()
          },
      },
    }
  },
  computed: {
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  created() {
    this.userInfo = getUserInfo()
    this.getTableData()
  },
  methods: {
    ...mapMutations('plan', ['savePlanId', 'saveBizLine']),
    // 表格信息
    async getTableData() {
      this.searchData.trainningStatus = TRAINNING_STATUS.commit.val
      const _editData = JSON.parse(JSON.stringify(this.searchData))
      _editData.categoryChildIdList = Tools.dropCategoryToStr(_editData.categoryChildIdList)
      const query = {
        pageCondition: this.tablePage,
        bizCondition: _editData,
      }

      this.tableLoading = true
      const {data} = await orgTrainPlanHubeiQuery(query)
      this.tableData = data.data
      data.data.forEach(item => {
        item.categoryName = Tools.listStrToName(item.categoryIdList, this.categoryAllMap)
      })
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },

    fomatCategory(row) {
      if (row.categoryIdList && row.categoryIdList.length > 0) {
        const _names = Tools.listStrToName(row.categoryIdList, this.categoryAllMap)
        return _names[0] + ' +' + _names.length
      } else {
        return ''
      }
    },
    // 搜索
    searchFormClick() {
      this.getTableData()
    },
    // 重置
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 跳转计划详情页
    goDetailsPage(row) {
      this.savePlanId(row.trainningId)
      this.saveBizLine(row.bizLine)
      // this.$emit('goDetailsPage')
      this.$router.push(`/train-archive/details`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../components/styles/index";
</style>
