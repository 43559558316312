var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("HSearch", {
        ref: "searchForm",
        attrs: {
          "search-data": _vm.searchData,
          "search-form": _vm.searchForm,
          "search-handle": _vm.searchHandle,
          "search-rules": _vm.searchRules,
          that: _vm.that,
          "label-width": "80px",
          size: "medium",
        },
      }),
      _c("HTable", {
        attrs: {
          "is-handle": true,
          "is-index": true,
          loading: _vm.tableLoading,
          "table-cols": _vm.tableCols,
          "table-data": _vm.tableData,
          "table-handles": _vm.tableHandles,
          "table-page": _vm.tablePage,
          that: _vm.that,
        },
        on: { selectionChange: _vm.selectionChange },
      }),
      _c("OfflineLearningDialog", {
        attrs: { "flag-visible": _vm.dialogShow, "stu-info": _vm.stuInfo },
        on: {
          "update:flagVisible": function ($event) {
            _vm.dialogShow = $event
          },
          "update:flag-visible": function ($event) {
            _vm.dialogShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }