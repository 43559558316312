import Layout from '@/layout'
import { ORG_USER_TYPE, USER_TYPE } from '@/const/sys'
// 培训管理
const trainningPlansRouter = {
  path: '/train',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '培训管理',
    icon: null,
    roles: [USER_TYPE.organization.val, USER_TYPE.government.val, ORG_USER_TYPE.gov_park_admin.val],
  },
  alwaysShow: true,
  children: [
    {
      path: 'plan-admin',
      component: () => import('@/views/company/trainning-admin-plan'),
      name: 'OrgStudentManagement-admin',
      meta: {
        title: '园区培训计划', icon: null,
        roles: [ORG_USER_TYPE.gov_park_admin.val],
      },
    },
    {
      path: 'planDetails-admin',
      component: () => import('@/views/company/trainning-admin-plan/detail'),
      name: 'planDetails-admin',
      hidden: true,
      meta: {
        title: '园区培训计划',
        icon: null,
        activeMenu: '/train/plan-admin',
        roles: [ORG_USER_TYPE.gov_park_admin.val],
      },
    },
    {
      path: 'plan',
      component: () => import('@/views/company/trainning-plan'),
      name: 'OrgStudentManagement',
      meta: { title: '企业培训计划', icon: null },
    },
    {
      path: 'planDetails',
      component: () => import('@/views/company/trainning-plan/detail'),
      name: 'planDetails',
      hidden: true,
      meta: { title: '企业培训计划', icon: null, activeMenu: '/train/plan' },
    },

  ],
}

export default trainningPlansRouter
