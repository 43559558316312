<template>
  <div className="app-container">
    <HSearch
      ref="searchForm"
      :search-data="searchData"
      :search-form="searchForm"
      :search-handle="searchHandle"
      :search-rules="searchRules"
      :that="that"
      label-width="80px"
      size="medium"
    />
    <HTable
      :is-handle="true"
      :is-index="true"
      :loading="tableLoading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-handles="tableHandles"
      :table-page="tablePage"
      :that="that"
    />
    <AddTrainPlanDialog
      :flag-visible.sync="dialogShow"
      :plan-info="planInfo"
      :title="Title"
    />
  </div>
</template>

<script>
import HSearch from '@/components/MySearch/index.vue'
import HTable from '@/components/MyTable/index.vue'
import AddTrainPlanDialog from './components/addPlanDialog.vue'
import Tools from '@/utils/tools'
import { PLAN_STATES, TRAINNING_STATUS, TRAINNING_TYPE, USER_TYPE } from '@/const/sys'
import { getUserInfo } from '@/utils/auth'
import { mapMutations } from 'vuex'
import { listTrainningPlan } from '@/api/company/trainningPlanAdmin'

export default {
  name: 'OrgTrainingPlan',
  components: {
    HSearch,
    HTable,
    AddTrainPlanDialog,
  },
  data() {
    const _TrainStatus = Tools.mapToSelctList(TRAINNING_STATUS)
    const _TRAINNING_TYPE = Tools.mapToSelctList(TRAINNING_TYPE)
    // if (getUserInfo().userType === USER_TYPE.government.val) {
    //   _TrainStatus.splice(0, 1)
    // }
    return {
      userInfo: {},
      PLAN_STATES,
      USER_TYPE,
      filterCategoryAllArr: [],
      // 标签页
      activeName: PLAN_STATES.draft.val,
      that: this,
      tableLoading: false,
      // 新增计划显示影藏
      dialogShow: false,
      // 新增，编辑计划弹框标题
      Title: '',
      // 当个计划信息
      planInfo: {},
      searchData: {
        trainningSerial: '',
        trainningType: '',
        trainningName: '',
        trainningStatus: '',
        orgName: '',
      },
      categoryObj: {}, // 培训类别对象
      searchForm: [
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'trainningSerial',
          placeholder: '请输入计划编号',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'trainningName',
          placeholder: '请输入计划名称',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'trainningType',
          width: '260px',
          placeholder: '请选择培训方式',
          options: _TRAINNING_TYPE,
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'trainningStatus',
          width: '260px',
          placeholder: '请选择计划状态',
          options: _TrainStatus,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [

        {
          label: '培训计划编号',
          prop: 'trainningSerial',
        },
        {
          label: '培训方式',
          prop: 'trainningType',
          formatter: row => Tools.formatDict(TRAINNING_TYPE, row.trainningType),
        },
        {
          label: '培训计划名称',
          prop: 'trainningName',
        },
        {
          label: '培训课程',
          prop: 'courseName',
        },
        {
          label: '培训开始时间',
          prop: 'trainningStartTime',
        },
        {
          label: '培训结束时间',
          prop: 'trainningEndTime',
        },
        {
          label: '培训企业数量',
          prop: 'orgNum',
        },
        {
          label: '创建人',
          prop: 'creator',
        },
        {
          label: '状态',
          prop: 'trainningStatus',
          formatter: row => Tools.formatDict(TRAINNING_STATUS, row.trainningStatus),
        },
        {
          label: '操作列',
          type: 'button',
          btnList:
            [
              {
                label: '详情',
                type: 'text',
                handle: (that, row) => that.goDetailsPage(row),
              },
            ],
        },
      ],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange:
          (that, val) => {
            this.tablePage.pageNum = val
            this.getTableData()
          },
        handlePageSizeChange:
          (that, val) => {
            this.tablePage.pageSize = val
            this.getTableData()
          },
      },
      tableHandles: [
        {
          label: '新增园区培训计划',
          type: 'primary',
          handle: () => this.addPlan(),
        },
      ],
    }
  },
  created() {
    this.userInfo = getUserInfo()
    this.getTableData()
  },
  methods: {
    ...mapMutations('plan', ['savePlanId', 'saveBizLine']),
    // 表格信息
    async getTableData() {
      const _editData = JSON.parse(JSON.stringify(this.searchData))
      _editData.categoryChildIdList = Tools.dropCategoryToStr(_editData.categoryChildIdList)
      const query = {
        pageCondition: this.tablePage,
        bizCondition: _editData,
      }

      // if (this.userInfo.userType === USER_TYPE.government.val) {
      //   query.bizCondition.trainningStatus = PLAN_STATES.commit.val
      // } else {
      //   query.bizCondition.trainningStatus = this.activeName
      // }
      this.tableLoading = true
      const { data } = await listTrainningPlan(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 培训类别下拉框切换时
    async bizLineChange(prop) {
      this.searchData.bizLine = prop
      await this.getTableData()
    },

    // 标签页切换
    handleTabsClick(tab, event) {
      this.getTableData()
    },
    // 搜索
    searchFormClick() {
      this.getTableData()
    },
    // 重置
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 新增计划
    addPlan() {
      this.Title = '新增园区培训计划'
      this.dialogShow = true
    },
    // 编辑计划按钮
    editClick(row) {
      this.planInfo = row
      this.Title = '编辑'
      this.dialogShow = true
    },

    // 跳转计划详情页
    goDetailsPage(row) {
      this.savePlanId(row.parkTrainningId)
      this.saveBizLine(row.bizLine)
      // this.$emit('goDetailsPage')
      this.$router.push(`/train/planDetails-admin`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../components/styles/index";
</style>
