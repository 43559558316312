var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt12 ml12 mr12 bgc-white br2" }, [
    _c(
      "div",
      {
        staticClass:
          "flex flex-row align-center justify-between pt12 pb12 pl16 pr16 bb-d9",
      },
      [
        _c("div", { staticClass: "fs20 c-181818" }, [
          _vm._v(_vm._s(_vm.detailTitle)),
        ]),
        _vm.noData
          ? _c(
              "div",
              { staticClass: "flex flex-row align-center fs14" },
              _vm._l(_vm.listHandles, function (item, index) {
                return (
                  item.isShow === null || item.isShow === undefined
                    ? true
                    : item.isShow(_vm.that)
                )
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticClass: "pr6 pointer",
                        class: [
                          item.disabled && item.disabled(_vm.that)
                            ? "c-7b7b7b"
                            : item.classStyle
                            ? item.classStyle
                            : "c-0067C0",
                        ],
                        on: {
                          click: function ($event) {
                            item.disabled && item.disabled(_vm.that)
                              ? ""
                              : item.handle(_vm.that)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.label) + " ")]
                    )
                  : _vm._e()
              }),
              0
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "flex flex-wrap  pt16 pb4 pl16 pr16 c-181818 fs14",
        style: _vm.contentPadding,
      },
      _vm._l(_vm.listConfig, function (item, index) {
        return (
          item.isShow === null || item.isShow === undefined
            ? true
            : item.isShow(_vm.that)
        )
          ? _c(
              "div",
              {
                key: index,
                staticClass: "flex flex-row pb12",
                style: _vm.itemWidth,
              },
              [
                _c("div", { class: item.titleClass }, [
                  _vm._v(_vm._s(item.placeholder) + "："),
                ]),
                item.type === "image"
                  ? _c(
                      "div",
                      [
                        _vm.list[item.prop]
                          ? _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                "preview-src-list": [_vm.list[item.prop]],
                                src: _vm.list[item.prop],
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-data.png"),
                              },
                            }),
                      ],
                      1
                    )
                  : item.type === "multipleImage"
                  ? _c("div", [
                      _vm.list[item.prop] && _vm.list[item.prop].length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.list[item.prop],
                              function (imgItem, imgIndex) {
                                return _c("el-image", {
                                  key: imgIndex,
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    "margin-right": "10px",
                                  },
                                  attrs: {
                                    "preview-src-list": _vm.list[item.prop],
                                    src: imgItem,
                                  },
                                })
                              }
                            ),
                            1
                          )
                        : _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-data.png"),
                              },
                            }),
                          ]),
                    ])
                  : item.isLink
                  ? _c(
                      "div",
                      { class: [_vm.list[item.prop] ? item.contentClass : ""] },
                      _vm._l(
                        _vm.conversion(_vm.list[item.prop]),
                        function (fileName, fileIndex) {
                          return _c(
                            "div",
                            {
                              key: fileIndex,
                              staticClass: "mb10 pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.toLink(
                                    _vm.list[item.prop][fileIndex].url
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(fileName || "-") + " ")]
                          )
                        }
                      ),
                      0
                    )
                  : _c(
                      "div",
                      { class: [_vm.list[item.prop] ? item.contentClass : ""] },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              (item.formatter && item.formatter(_vm.list)) ||
                                _vm.list[item.prop] ||
                                "-"
                            ) +
                            " "
                        ),
                      ]
                    ),
              ]
            )
          : _vm._e()
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }