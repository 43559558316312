import request from '@/utils/request'

// 园区端查询培训计划列表
export function listTrainningPlan(data) {
  return request({
    url: '/parkTrainningPlan/listTrainningPlan',
    method: 'post',
    data,
  })
}

// 园区端提交培训计划
export function comMintPlan(planId) {
  return request({
    url: '/parkTrainningPlan/comMintPlan?planId=' + planId,
    method: 'post',
  })
}

// 园区端删除培训计划
export function removePlanId(planId) {
  return request({
    url: '/parkTrainningPlan/removePlanId?planId=' + planId,
    method: 'post',
  })
}

// 园区端新增培训计划
export function savePlanCourse(data) {
  return request({
    url: '/parkTrainningPlan/savePlanCourse',
    method: 'post',
    data,
  })
}

// 园区端编辑时获取单条培训计划信息
export function trainningPlanInfo(planId) {
  return request({
    url: '/trainningPlan/selectPlanById',
    method: 'get',
    params: {
      planId,
    },
  })
}

// 园区端修改培训计划
export function orgUpdateTrainningPlan(data) {
  return request({
    url: '/parkTrainningPlan/updateTrainningPlan',
    method: 'post',
    data,
  })
}

// 监管端培训计划审批
export function auditMintPlan(data) {
  return request({
    url: '/trainningPlan/auditMintPlan',
    method: 'post',
    data,
  })
}

// 计划管理 培训计划详情 批量添加学员
export function saveTrainningOrg(data) {
  return request({
    url: '/parkTrainningOrg/saveTrainningOrg',
    method: 'post',
    data,
  })
}

// 获取未分配学员
export function listParkOrg(data) {
  return request({
    url: '/parkTrainningOrg/listParkOrg',
    method: 'post',
    data,
  })
}

// 计划管理 培训计划详情 批量移除学员
export function removeTrainningOrg(data) {
  return request({
    url: '/parkTrainningOrg/removeTrainningOrg?id=' + data,
    method: 'post',
    data,
  })
}

// 园区端计划管理 查看计划详情
export function selectPlanById(planId) {
  return request({
    url: `/parkTrainningPlan/selectPlanById`,
    method: 'get',
    params: {
      planId,
    },
  })
}

// 园区端计划管理 查看计划学员信息
export function listTrainPlanOrg(data) {
  return request({
    url: `/parkTrainningOrg/listTrainPlanOrg`,
    method: 'post',
    data,
  })
}

// 园区端 添加培训计划 查询培训课程
export function getListTrainningCourse(data) {
  return request({
    url: `/course/queryCourseInfo`,
    method: 'post',
    data,
  })
}

