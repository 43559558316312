<template>
  <el-dialog
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :title="title"
    :visible.sync="dialogVisible"
    width="800px"
    @close="close"
    @open="open"
  >
    <MyForm
      ref="editForm"
      :edit-cfg="editForm"
      :edit-data="editData"
      :edit-rules="editRules"
      :that="that"
      labelMaxWidth="135px"
    />
    <div slot="footer" class="dialog-footer">
      <el-button class="mr10" @click="close">取消</el-button>
      <el-button type="primary" @click="submitBnt">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import MyForm from '@/components/MyForm/index.vue'
import {
  addTrainningPlan,
  getListTrainningCourse, queryPaperByCategoryId,
  trainningPlanInfo,
  upDateTrainningPlan,
} from '@/api/company/trainningPlan'
import {EXAM_ROOM_STATUS, PLAN_SOURCE, TRAINNING_TYPE, USER_TYPE, TOOLBOX_STUDY_TYPE} from '@/const/sys'
import moment from '@/utils/moment'
import {mapState} from 'vuex'
import Tools from '@/utils/tools'
import {getUserInfo} from '@/utils/auth'

export default {
  name: 'AddTrainPlanDialog',
  components: {
    MyForm,
  },
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    courseId: {
      type: Number,
      default: 0,
    },
    planInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    const _TRAINNING_TYPE = Tools.mapToSelctList(TRAINNING_TYPE)
    const _TOOLBOX_STUDY_TYPE = Tools.mapToSelctList(TOOLBOX_STUDY_TYPE)
    return {
      categoryList: [],
      dialogVisible: false,
      courseItems: [], // 课程列表
      that: this,
      editForm: [
        {
          label: '培训计划名称',
          prop: 'trainningName',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
          disabled: () => this.planInfo && this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val,
        },
        {
          type: 'select',
          label: '培训方式',
          prop: 'trainningType',
          placeholder: '请选择',
          options: _TRAINNING_TYPE,
          isClearable: true,
          disabled: () => this.planInfo && this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val,
          change: (that, val) => this.changeTrainningType(val),
        },
        {
          type: 'select',
          label: '培训课程',
          prop: 'courseId',
          placeholder: '请选择',
          options: [],
          isClearable: true,
          disabled: () => this.planInfo && this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val,
        },
        {
          label: '园区要求开始时间',
          prop: 'parkTrainningStartTime',
          type: 'dateTime',
          format: 'yyyy-MM-dd HH:mm',
          placeholder: '选择日期',
          options: [],
          isClearable: true,
          isShow: () => this.planInfo && this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val,
        },
        {
          label: '园区要求结束时间',
          prop: 'parkTrainningEndTime',
          type: 'dateTime',
          format: 'yyyy-MM-dd HH:mm',
          placeholder: '选择日期',
          options: [],
          isClearable: true,
          isShow: () => this.planInfo && this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val,
        },
        {
          label: '计划培训开始时间',
          prop: 'startTime',
          type: 'dateTime',
          format: 'yyyy-MM-dd HH:mm',
          placeholder: '选择日期',
          options: [],
          isClearable: true,
        },
        {
          label: '计划培训结束时间',
          prop: 'endTime',
          type: 'dateTime',
          format: 'yyyy-MM-dd HH:mm',
          placeholder: '选择日期',
          options: [],
          isClearable: true,
        },
        {
          label: '培训地点',
          prop: 'trainningAddress',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
        },
        {
          label: '授课人',
          prop: 'teacher',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
        },
        {
          label: '      ',
          prop: 'isUseToolBox',
          type: 'checkboxFlag',
          text: '使用培训工具箱',
          isShow: (that, row) => that.isVisibleToolBoxCheck,
          change: (that, val) => this.changeToolBoxCheck(),
        },
        {
          type: 'select',
          label: '培训内容',
          prop: 'trainingToolbox',
          placeholder: '请选择',
          options: _TOOLBOX_STUDY_TYPE,
          isClearable: true,
          isShow: (that, row) => that.isVisibleToolBoxSelect,
        },
      ],
      editData: {
        trainningName: '',
        trainningType: '',
        courseId: '',
        startTime: '',
        endTime: '',
        trainningAddress: '',
        teacher: '',
        isUseToolBox: false,
        trainingToolbox: '',
      },
      editRules: {
        trainningName: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        trainningType: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
        courseId: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
        startTime: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
        endTime: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
        trainingToolbox: [
          {required: false, message: '请选择', trigger: 'change'},
        ],
      },
      isEdit: false,
      isVisibleToolBoxCheck: false,
      isVisibleToolBoxSelect: false,
    }
  },
  computed: {
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
    title: {
      handler(newValue, oldValue) {
        this.title = newValue
      },
    },
    courseId: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.editData.courseId = newValue
          this.editData.trainningType = TRAINNING_TYPE['online-study'].val
        }
      },
    },
  },
  created() {
    this.getTrainCourseList()
  },
  methods: {
    // 打开弹窗时
    async open() {
      try {
        this.editData.trainingToolbox = ''
        this.isVisibleToolBoxCheck = false
        this.isVisibleToolBoxSelect = false
        this.editRules.trainingToolbox[0].required = false

        if (this.planInfo && this.planInfo.planId) {
          if (this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val) {
            this.editForm.forEach(i => {
              if (i.prop === 'trainningName' || i.prop === 'trainningType' || i.prop === 'courseId' || i.prop === 'parkTrainningStartTime' || i.prop === 'parkTrainningEndTime') {
                i.type = 'txt'
              }
            })
            delete this.editRules.trainningName
            delete this.editRules.trainningType
            delete this.editRules.courseId
          }
          this.isEdit = true
          await this.getPlanInfo()
          this.changeToolBoxCheck()
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 关闭弹窗
    close() {
      this.$parent.courseId = 0
      Tools.resetObject(this.editData)
      this.dialogVisible = false
      this.$emit('update:flagVisible', false)
    },
    async getTrainCourseList() {
      const selects = Tools.findSelect(['courseId'], this.editForm)
      const _userInfo = getUserInfo()
      const _whiteList = ['172619477834'] // 演示账号只放开默认课程
      const data = await getListTrainningCourse({cateStatus: EXAM_ROOM_STATUS.active.val})
      this.courseItems = data.data
      let _data = data.data
      if (_userInfo.userType === USER_TYPE.organization.val && _whiteList.indexOf(_userInfo.orgInfo.orgNo) >= 0) {
        const _d = _data.find(m => m.courseId == 5)
        _data = [_d]
      }
      const options = Tools.formatOpt(
        [_data],
        [{value: 'courseId', label: 'courseName'}],
      )
      Tools.assignValue(selects, options)
    },
    // 如果是编辑计划 打开弹框获取当前计划的信息
    async getPlanInfo() {
      const {data} = await trainningPlanInfo(this.planInfo.planId)
      const _data = JSON.parse(JSON.stringify(data))
      _data.startTime = moment(_data.startTime).format('YYYY-MM-DD HH:mm')
      _data.endTime = moment(_data.endTime).format('YYYY-MM-DD HH:mm')
      this.editData = _data
      if (this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val) {
        this.editData.trainningType = TRAINNING_TYPE[_data.trainningType].txt
        this.editData.courseId = _data.courseName
      }
      if (this.editData.trainningType === 'offline') {
        this.isVisibleToolBoxCheck = true
        if (this.editData.trainingToolbox === null || this.editData.trainingToolbox === '') {
          this.editData.isUseToolBox = false
          this.isVisibleToolBoxSelect = false
        } else {
          this.editData.isUseToolBox = true
          this.isVisibleToolBoxSelect = true
        }
      }
    },
    changeTrainningType(val) {
      this.editData.trainingToolbox = ''
      if (val === 'online-study') {
        this.isVisibleToolBoxCheck = false
        this.isVisibleToolBoxSelect = false
        this.editRules.trainingToolbox[0].required = false
      } else {
        this.isVisibleToolBoxCheck = true
        this.editData.isUseToolBox = false
      }
    },
    changeToolBoxCheck() {
      this.isVisibleToolBoxSelect = this.editData.isUseToolBox
      if (this.editData.isUseToolBox) {
        this.editRules.trainingToolbox[0].required = true
      } else {
        this.editData.trainingToolbox = ''
        this.editRules.trainingToolbox[0].required = false
      }
    },
    // 判断课程是否有对应试卷
    async queryTestPaperByCategoryId(categoryId) {
      if (this.editData.trainningType === TRAINNING_TYPE.offline.val && (this.editData.trainingToolbox === TOOLBOX_STUDY_TYPE.noexam.val || this.editData.trainingToolbox === '')) {
        return true
      }
      const {success, data} = await queryPaperByCategoryId(categoryId)
      if (success && data && data.length > 0) {
        return true
      } else {
        return this.$confirm('因课程无对应的考试试卷，系统无法自动创建考试计划，请后续人工创建考试计划。确认添加？', '提示', {
          confirmButtonText: '确认添加',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          return true
        }).catch(() => {
          return false
        })
      }
    },
    // 新增、编辑计划提交按钮
    submitBnt() {
      let flag = true
      const _editData = JSON.parse(JSON.stringify(this.editData))
      _editData.startTime = moment(_editData.startTime).format('YYYY-MM-DD HH:mm')
      _editData.endTime = moment(_editData.endTime).format('YYYY-MM-DD HH:mm')
      if (!this.isEdit) {
        this.$refs.editForm.$refs.editForm.validate(async valid => {
          if (valid) {
            _editData.categoryId = this.courseItems.find(({courseId}) => courseId === this.editData.courseId).categoryId
            flag = await this.queryTestPaperByCategoryId(_editData.categoryId)
            if (flag) {
              const {success, data} = await addTrainningPlan(_editData)
              if (success) {
                this.$message.success(data)
              } else {
                this.$message.warning(data)
              }
              this.close()
              if (!this.courseId) {
                this.$parent.getTableData()
              }
            }
          }
        })
      } else {
        if (this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val && !moment(_editData.parkTrainningEndTime).isSameOrAfter(_editData.endTime)) {
          this.$message.warning('培训结束时间不能大于园区要求结束时间')
          return
        }
        this.$refs.editForm.$refs.editForm.validate(async valid => {
          if (valid) {
            _editData.trainningId = this.planInfo.trainningId
            const _obj = this.courseItems.find(({courseId}) => courseId === this.editData.courseId) || ''
            if (this.planInfo.planSource === PLAN_SOURCE.ParkSchedule.val) {
              _editData.courseId = this.planInfo.courseId
            }
            _editData.categoryId = _obj ? _obj.categoryId : ''
            flag = await this.queryTestPaperByCategoryId(_editData.categoryId)
            if (flag) {
              const {success, data} = await upDateTrainningPlan(_editData)
              if (success) {
                this.$message.success(data)
                this.close()
                this.$parent.getTableData()
                this.$parent.getPlanData()
              } else {
                this.$message.warning(data)
                this.close()
                this.$parent.getTableData()
                this.$parent.getPlanData()
              }
            }
          }
        })
      }
    },
  },
}
</script>
