<template>
  <el-dialog
    :destroy-on-close="true"
    :visible.sync="dialogVisible"
    title="添加员工"
    width="1300px"
    @close="close"
    @open="open"
  >
    <div style="height: 600px">
      <HSearch
        ref="searchForm"
        :search-data="searchData"
        :search-form="searchForm"
        :search-handle="searchHandle"
        :search-rules="searchRules"
        :that="that"
        label-width="80px"
        size="medium"
        @selectionChange="selectionChange"
      />
      <HTable
        :is-handle="true"
        :is-index="true"
        :is-selection="true"
        :loading="tableLoading"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-page="tablePage"
        :that="that"
        class="el_tables"
        @selectionChange="selectionChange"
      />
      <div class="buttons">
        <div class="buttons-right">
          <div class="buttons-right-center">
            <el-button class="mr10" type="primary" @click="submitBtn">确认添加</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import HSearch from '@/components/MySearch/index.vue'
import HTable from '@/components/MyTable/index.vue'
import { getTrainningUnEnroll, saveTrainningEnroll } from '@/api/company/trainningPlan'
import Tools from '@/utils/tools'
import { BIZLINE, JOB_POSITION, STU_TYPE } from '@/const/sys'
import { mapState } from 'vuex'

export default {
  name: 'AllotStuDialog',
  components: {
    HSearch,
    HTable,
  },
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
    planInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    // const _learningProcess = Tools.mapToSelctList(LEARNING_PROCESS)
    // const _auditStatus = Tools.mapToSelctList(AUDIT_DISTINCT)
    const _stuType = Tools.mapToSelctList(STU_TYPE)
    return {
      // 单个学生信息
      stuInfo: {},
      // 表格选中数据
      tableActiveList: [],
      dialogVisible: false,
      that: this,
      tableLoading: false,
      selectArr: [],
      searchData: {
        userName: '',
        idCard: '',
        phone: '',
        stuType: '',
        organization: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          prop: 'userName',
          placeholder: '请输入姓名',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'idCard',
          placeholder: '请输入身份证号',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'phone',
          placeholder: '请输入手机号',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'stuType',
          placeholder: '请选择人员类别',
          options: _stuType,
          isClearable: true,
        },
        {
          type: 'date',
          label: '',
          prop: 'startTime',
          placeholder: '开始日期',
          format: 'yyyy-MM-dd',
          isClearable: true,
        },
        {
          type: 'date',
          label: '',
          prop: 'endTime',
          placeholder: '结束日期',
          format: 'yyyy-MM-dd',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'organization',
          placeholder: '请输入所属单位',
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '人员类别',
          prop: 'stuType',
          formatter: row => Tools.formatDict(STU_TYPE, row.stuType),
          width: '140px',
        },
        {
          label: '姓名',
          prop: 'realName',
        },
        {
          label: '身份证号',
          prop: 'idCard',
          width: '200px',
        },
        {
          label: '手机号',
          prop: 'phone',
        },
        {
          label: '所属单位',
          prop: 'organization',
        },
        {
          label: '工作岗位',
          prop: 'job',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
        {
          label: '建立日期',
          prop: 'startTime',
        },
      ],
      tablePage: {
        pageSize: 50,
        size: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          this.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          this.getTableData()
        },
      },
    }
  },
  computed: {
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
  },
  created() {
  },
  methods: {
    // 获取可分配学员数据
    async getTableData() {
      this.tableLoading = true
      const _searchData = JSON.parse(JSON.stringify(this.searchData))
      _searchData.stuType = parseInt(_searchData.stuType) // 数据库存储的为数值类型
      const query = {
        pageCondition: this.tablePage,
        bizCondition: _searchData,
      }
      query.bizCondition.trainningId = this.planInfo.planId
      const { data } = await getTrainningUnEnroll(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },

    // 打开弹窗时
    async open() {
      try {
        await this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    // 关闭弹窗
    close() {
      this.dialogVisible = false
      this.$emit('update:flagVisible', false)
    },
    // 搜索
    searchFormClick() {
      this.getTableData()
    },
    // 重置
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 表格勾选中
    selectionChange(selection) {
      this.tableActiveList = selection
    },
    // 确认分配按钮
    async submitBtn() {
      const list = this.tableActiveList
      const stuInfo = {}
      const stuNoList = []
      if (list.length === 0) {
        return this.$alert('请选择分配学员', '提示', {
          confirmButtonText: '确定',
        })
      } else {
        list.forEach((item, index) => {
          stuNoList.push(item.stuId)
        })
        stuInfo.planId = this.planInfo.planId
        stuInfo.stuIdList = stuNoList
        const { success, data } = await saveTrainningEnroll(stuInfo)
        if (success) {
          this.$message.success(data)
          this.$parent.getTableData()
          this.close()
        } else {
          this.$message.warning(data)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../components/styles/index";

.el_tables {
  max-height: 450px;
  overflow-y: scroll;
}

.buttons {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0
}

.buttons-right {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

/deep/ .ces-pagination {
  margin-bottom: 0;
}

/deep/ .ces-handle {
  margin: 0;
  padding: 0;
}
</style>
