<template>
  <div>
    <div class="header-box flex flex-row align-center justify-between">
      <div class="title">详情</div>
      <div>
        <div class="mouse-style" style="float: left" @click="commitPhotosClick">上传培训过程照片</div>
        <div class="mouse-style" style="float: left;margin-left: 20px" @click="goBack">返回</div>
      </div>
    </div>
    <myDetail :item-width="itemWidth" :list="list" :list-config="listConfig" :that="that"/>
    <div class="mt12 ml12 mr12 bgc-white br2">
      <div class="flex flex-row justify-between align-center pt12 pb12 pl16 pr16 bb-d9">
        <div class="fs20 c-181818">员工管理</div>
      </div>
      <HSearch
        ref="searchForm"
        :search-data="searchData"
        :search-form="searchForm"
        :search-handle="searchHandle"
        :search-rules="searchRules"
        :that="that"
        label-width="80px"
        size="medium"
      />
      <div v-if="isShowExamResultAction" class="mt12 ml50">
        <el-button class="mr10" type="primary" @click="changeExamResultClick(`${EXAM_RESULT_STATUS.pass.val}`)">
          批量考试合格
        </el-button>
        <el-button type="primary" @click="changeExamResultClick(`${EXAM_RESULT_STATUS.fail.val}`)">
          批量考试不合格
        </el-button>
        <span
          style="margin-left: 16px;color: #7B7B7B;font-size: 13px">说明：仅线下培训且不用培训工具箱，才需人工标注合格情况</span>
      </div>
      <HTable
        :is-handle="true"
        :is-index="true"
        :is-selection="isShowExamResultAction"
        :loading="tableLoading"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-page="tablePage"
        :that="that"
        @selectionChange="selectionChange"
      />
    </div>

    <div class="ml12 mr12 bgc-white br2" style="margin-top: -38px">
      <div class="flex flex-row justify-between pt12 pb12 pl16 pr16 bb-d9">
        <div class="fs20 c-181818">培训过程拍照</div>
        <div class="mouse-style" @click="dialogPhotoFormVisible=true">
          编辑
        </div>
      </div>
      <div>
        <div class="mt12 ml12 mr12 fs16">
          说明：主要用于线下培训后，上传现场拍照等培训凭证。线上培训时系统自动抓拍照片，此处上传资料不是必须操作。
        </div>
        <div class="mt12 ml12 mr12 fs16">
          <div style="float: left">
            培训过程附件：
          </div>
          <div v-if="list.trainPhotoArray && list.trainPhotoArray.length>0">
            <el-image
              v-for="(imgItem,imgIndex) in list.trainPhotoArray"
              :key="imgIndex"
              :preview-src-list="list.trainPhotoArray"
              :src="imgItem"
              style="width: 100px; height: 100px;margin-right: 10px"
            />
          </div>
          <div v-else>
            <img src="~@/assets/images/no-data.png">
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogPhotoFormVisible" title="上传培训过程拍照" width="750px">
      <MyForm
        ref="editPhotoForm"
        :edit-cfg="archiveFileForm"
        :edit-data="archiveFileData"
        :that="that"
        labelMaxWidth="135px"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogPhotoFormVisible = false">取 消</el-button>
        <el-button style="margin-left: 16px" type="primary" @click="eidtTrainArchivePhoto">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HSearch from '@/components/MySearch/index.vue'
import HTable from '@/components/MyTable/index.vue'
import {mapState} from 'vuex'
import {
  getListTrainningEnroll,
  getPlanInfo, upDateTrainningPlan,
} from '@/api/company/trainningPlan'
import myDetail from '@/components/MyDetail/index.vue'
import Tools from '@/utils/tools'
import {
  JOB_POSITION,
  PLAN_STATES,
  STU_TYPE,
  TRAINNING_TYPE,
  USER_TYPE,
  TOOLBOX_STUDY_TYPE,
  EXAM_RESULT_STATUS,
} from '@/const/sys'

import {getUserInfo} from '@/utils/auth'
import MyForm from "@/components/MyForm/index.vue";
import {studentImgPath} from "@/const/config";
import {batchUpdateExamResult} from "@/api/company/learnning";

export default {
  name: 'TrainArchiveDetails',
  components: {
    MyForm,
    myDetail,
    HSearch,
    HTable,
  },
  data() {
    const _stuType = Tools.mapToSelctList(STU_TYPE)
    return {
      dialogPhotoFormVisible: false,
      USER_TYPE,
      PLAN_STATES,
      userInfo: {},
      planInfo: {},
      isShowExamResultAction: false, // 默认隐藏
      stuInfo: {},
      that: this,
      list: {},
      // 表格选中数据
      tableActiveList: [],
      itemWidth: {width: '31%', marginBottom: '1%'},
      listConfig: [
        {
          prop: 'trainningName',
          placeholder: '培训计划名称',
        },
        {
          prop: 'startTime',
          placeholder: '培训开始时间',
        },
        {
          prop: 'endTime',
          placeholder: '培训结束时间',
        },
        {
          prop: 'trainningAddress',
          placeholder: '培训地点',
        },
        {
          prop: 'teacher',
          placeholder: '授课人',
        },
        {
          prop: 'trainningType',
          placeholder: '培训方式',
          formatter: row => Tools.formatDict(TRAINNING_TYPE, row.trainningType),
        },
        {
          prop: 'courseName',
          placeholder: '培训课程',
        },
        {
          prop: 'isUseToolBox',
          placeholder: '使用培训工具箱',
        },
        {
          prop: 'trainingToolbox',
          placeholder: '培训内容',
          formatter: row => Tools.formatDict(TOOLBOX_STUDY_TYPE, row.trainingToolbox),
        },
      ],
      tableLoading: false,
      // 分配学员弹框显示影藏
      allotStuDialogShow: false,
      // 审核学员资料弹框
      dialogShow: false,
      // 查看资料弹框标题
      Title: '',
      searchData: {
        realName: '',
        phone: '',
        stuType: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          prop: 'realName',
          placeholder: '请输入姓名',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'phone',
          placeholder: '请输入手机号',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'stuType',
          placeholder: '请选择人员类别',
          options: _stuType,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '姓名',
          prop: 'realName',
        },
        {
          label: '人员类别', // 内部员工/外部员工
          prop: 'stuType',
          formatter: row => Tools.formatDict(STU_TYPE, row.stuType),
        },
        {
          label: '身份证号',
          prop: 'idCard',
        },
        {
          label: '手机号',
          prop: 'phone',
        },
        {
          label: '所属单位',
          prop: 'organization',
        },
        {
          label: '工作岗位',
          prop: 'job',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
      ],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          this.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          this.getTableData()
        },
      },

      archiveFileForm: [
        {
          type: 'multipleImgUpload',
          label: '培训过程附件',
          prop: 'trainPhotoArray',
          requestFlag: true,
          isPriview: true,
          limit: 10,
          imgSize: '2',
          uploadPath: studentImgPath.otherImgPath,
        },
      ],
      archiveFileData: {
        trainPhotoArray: [],
      },
    }
  },
  computed: {
    EXAM_RESULT_STATUS() {
      return EXAM_RESULT_STATUS
    },
    ...mapState('plan', {
      planId: state => state.planId,
      bizLine: state => state.bizLine,
    }),
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  created() {
    this.userInfo = getUserInfo()
    this.getPlanData()
    this.getTableData()
  },
  methods: {
    async getPlanData() {
      const {data} = await getPlanInfo(this.planId)
      this.list = data
      if (data.trainingToolbox === null || data.trainingToolbox === '') {
        this.list.isUseToolBox = '未使用'
      } else {
        this.list.isUseToolBox = '使用'
      }
      this.archiveFileData.trainPhotoArray = JSON.parse(JSON.stringify(data.trainPhotoArray))
    },
    // 获取表格数据
    async getTableData() {
      this.tableLoading = true
      const _searchData = JSON.parse(JSON.stringify(this.searchData))
      _searchData.stuType = parseInt(_searchData.stuType) // 数据库存储的为数值类型
      const query = {
        pageCondition: this.tablePage,
        bizCondition: _searchData,
      }
      query.bizCondition.trainningId = this.planId

      const {data} = await getListTrainningEnroll(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false

      if (this.userInfo.userType === USER_TYPE.organization.val
        && this.list.trainningType === 'offline'
        && (this.list.trainingToolbox ?? '') === ''
      ) {
        this.isShowExamResultAction = true
      }
    },
    // 搜索按钮
    searchFormClick() {
      this.getTableData()
    },
    // 重置按钮
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 确认培训归档附件图片
    eidtTrainArchivePhoto() {
      this.list.trainPhotoArray = this.archiveFileData.trainPhotoArray
      this.dialogPhotoFormVisible = false
    },
    // 表格勾选中
    selectionChange(selection) {
      this.tableActiveList = selection
    },
    // 改变考试结果状态
    changeExamResultClick(resVal) {
      console.log('参数：' + resVal)
      const tableList = this.tableActiveList
      const stuNoList = []
      if (tableList.length === 0) {
        return this.$alert('请选择考试学员', '提示', {
          confirmButtonText: '确定',
        })
      } else {
        tableList.forEach((item, index) => {
          stuNoList.push(item.enrollId)
        })
        const statusText = resVal === EXAM_RESULT_STATUS.pass.val ? '合格' : '不合格'
        this.$confirm('是否将' + stuNoList.length + '条记录审批为考试' + statusText + '?', '确认信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const _data = {'enrollIdList': stuNoList, 'examResult': resVal}
          const {success, data} = await batchUpdateExamResult(_data)
          if (success) {
            this.getTableData()
            this.$message({
              type: 'success',
              message: '修改成功',
            })
          } else {
            this.$message({
              type: 'info',
              message: '修改失败',
            })
            this.$message.error('修改失败')
          }
        })
      }
    },
    commitPhotosClick() {
      if (!this.archiveFileData.trainPhotoArray) {
        this.$message.warning('请上传培训过程照片')
        return
      }
      this.$confirm('是否上传培训过程照片', '上传培训过程照片', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        this.list.trainPhotoArray = this.archiveFileData.trainPhotoArray
        const res = await upDateTrainningPlan(this.list)
        if (res.success) {
          this.$message.success('上传培训过程照片成功')
        } else {
          this.$message.warning('上传培训过程照片失败')
        }
      })
    },

    // 点击返回 返回计划列表页
    goBack() {
      // this.$emit('goListPage')
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .ces-handle {
  margin: 0;
  padding: 0;
}
</style>
