var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticStyle: { "background-color": "#1C54C2" } }, [
      _c("div", { staticClass: "f-w700 fs50 header-content c-white" }, [
        _vm._v("应急安全教育专业平台"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }