var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "header-box flex flex-row align-center justify-between",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("详情")]),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "mouse-style",
                staticStyle: { float: "left" },
                on: { click: _vm.commitPhotosClick },
              },
              [_vm._v("上传培训过程照片")]
            ),
            _c(
              "div",
              {
                staticClass: "mouse-style",
                staticStyle: { float: "left", "margin-left": "20px" },
                on: { click: _vm.goBack },
              },
              [_vm._v("返回")]
            ),
          ]),
        ]
      ),
      _c("myDetail", {
        attrs: {
          "item-width": _vm.itemWidth,
          list: _vm.list,
          "list-config": _vm.listConfig,
          that: _vm.that,
        },
      }),
      _c(
        "div",
        { staticClass: "mt12 ml12 mr12 bgc-white br2" },
        [
          _vm._m(0),
          _c("HSearch", {
            ref: "searchForm",
            attrs: {
              "search-data": _vm.searchData,
              "search-form": _vm.searchForm,
              "search-handle": _vm.searchHandle,
              "search-rules": _vm.searchRules,
              that: _vm.that,
              "label-width": "80px",
              size: "medium",
            },
          }),
          _vm.isShowExamResultAction
            ? _c(
                "div",
                { staticClass: "mt12 ml50" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.changeExamResultClick(
                            "" + _vm.EXAM_RESULT_STATUS.pass.val
                          )
                        },
                      },
                    },
                    [_vm._v(" 批量考试合格 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.changeExamResultClick(
                            "" + _vm.EXAM_RESULT_STATUS.fail.val
                          )
                        },
                      },
                    },
                    [_vm._v(" 批量考试不合格 ")]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "16px",
                        color: "#7B7B7B",
                        "font-size": "13px",
                      },
                    },
                    [
                      _vm._v(
                        "说明：仅线下培训且不用培训工具箱，才需人工标注合格情况"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("HTable", {
            attrs: {
              "is-handle": true,
              "is-index": true,
              "is-selection": _vm.isShowExamResultAction,
              loading: _vm.tableLoading,
              "table-cols": _vm.tableCols,
              "table-data": _vm.tableData,
              "table-page": _vm.tablePage,
              that: _vm.that,
            },
            on: { selectionChange: _vm.selectionChange },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "ml12 mr12 bgc-white br2",
          staticStyle: { "margin-top": "-38px" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-row justify-between pt12 pb12 pl16 pr16 bb-d9",
            },
            [
              _c("div", { staticClass: "fs20 c-181818" }, [
                _vm._v("培训过程拍照"),
              ]),
              _c(
                "div",
                {
                  staticClass: "mouse-style",
                  on: {
                    click: function ($event) {
                      _vm.dialogPhotoFormVisible = true
                    },
                  },
                },
                [_vm._v(" 编辑 ")]
              ),
            ]
          ),
          _c("div", [
            _c("div", { staticClass: "mt12 ml12 mr12 fs16" }, [
              _vm._v(
                " 说明：主要用于线下培训后，上传现场拍照等培训凭证。线上培训时系统自动抓拍照片，此处上传资料不是必须操作。 "
              ),
            ]),
            _c("div", { staticClass: "mt12 ml12 mr12 fs16" }, [
              _c("div", { staticStyle: { float: "left" } }, [
                _vm._v(" 培训过程附件： "),
              ]),
              _vm.list.trainPhotoArray && _vm.list.trainPhotoArray.length > 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.list.trainPhotoArray,
                      function (imgItem, imgIndex) {
                        return _c("el-image", {
                          key: imgIndex,
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            "margin-right": "10px",
                          },
                          attrs: {
                            "preview-src-list": _vm.list.trainPhotoArray,
                            src: imgItem,
                          },
                        })
                      }
                    ),
                    1
                  )
                : _c("div", [
                    _c("img", {
                      attrs: { src: require("@/assets/images/no-data.png") },
                    }),
                  ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogPhotoFormVisible,
            title: "上传培训过程拍照",
            width: "750px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPhotoFormVisible = $event
            },
          },
        },
        [
          _c("MyForm", {
            ref: "editPhotoForm",
            attrs: {
              "edit-cfg": _vm.archiveFileForm,
              "edit-data": _vm.archiveFileData,
              that: _vm.that,
              labelMaxWidth: "135px",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogPhotoFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "16px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.eidtTrainArchivePhoto },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex flex-row justify-between align-center pt12 pb12 pl16 pr16 bb-d9",
      },
      [_c("div", { staticClass: "fs20 c-181818" }, [_vm._v("员工管理")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }