<template>
  <header style="background-color:#1C54C2">
    <div class="f-w700 fs50 header-content c-white">应急安全教育专业平台</div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import "src/layout/front/styles/header";
</style>
