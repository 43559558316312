<template>
  <div class="app-container">
    <div class="header-box flex flex-row align-center justify-between">
      <div class="title">详情</div>
      <div class="mouse-style" @click="goBack">返回</div>
    </div>
    <myDetail :item-width="itemWidth" :list="list" :list-config="listConfig" :list-handles="listHandles" :that="that"/>
    <div class="mt12 ml12 mr12 bgc-white br2">
      <!--      学员-->
      <div class="flex flex-row justify-between align-center pt12 pb12 pl16 pr16 bb-d9">
        <div class="fs20 c-181818">考生管理</div>
      </div>
      <HSearch
        ref="searchForm"
        :search-data="searchData"
        :search-form="searchForm"
        :search-handle="searchHandle"
        :search-rules="searchRules"
        :that="that"
        label-width="80px"
        size="medium"
      />
      <div
        v-if="userInfo.userType===USER_TYPE.organization.val&&list.auditStatus === null"
        class="flex flex-row justify-end c-0067C0 pr30 fs14 pointer"
        @click="allotStuClick"
      >添加考生
      </div>
      <HTable
        :is-handle="true"
        :is-index="true"
        :loading="tableLoading"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-page="tablePage"
        :that="that"
      />
    </div>
    <OrgLearningDialog :flag-visible.sync="studyDetailsDialogShow" :is-show-fun="isShowFun" :stu-info="stuInfo"/>
    <OfflineLearningDialog :flag-visible.sync="offlineStudyDetailsdialogShow" :is-show-fun="isShowFun" :stu-info="stuInfo"/>
    <AllotStuDialog
      :flag-visible.sync="allotStuDialogShow"
      :plan-info="list"
    />
    <AddPlanDialog
      :flag-visible.sync="dialogShow"
      :plan-info="planInfo"
      :title="Title"
    />
  </div>
</template>

<script>
import HSearch from '@/components/MySearch'
import HTable from '@/components/MyTable'
import AddPlanDialog from '../../components/addPlanDialog'
import AllotStuDialog from '../../components/allotStuDialog'
import OrgLearningDialog from '@/views/components/studyDetails'
import OfflineLearningDialog from '@/views/company/learning-management/components/offlineDetail'
import {mapState} from 'vuex'
import myDetail from '@/components/MyDetail'
import Tools from '@/utils/tools'
import {examStatus, GovAuditStatus, JOB_POSITION, ORG_USER_TYPE, STU_TYPE, USER_TYPE} from '@/const/sys'
import {getUserInfo} from '@/utils/auth'
import {
  enrollExamUnBind,
  examListDetail,
  queryUnAssignStu,
  sessionsActive,
  sessionsAudit,
  sessionsDelete,
} from '@/api/company/examPlan'
import moment from '@/utils/moment'

export default {
  name: 'ExamPlanDetails',
  components: {
    OfflineLearningDialog,
    myDetail,
    HSearch,
    HTable,
    AllotStuDialog,
    OrgLearningDialog,
    AddPlanDialog,
  },
  data() {
    const _stuType = Tools.mapToSelctList(STU_TYPE)
    return {
      auditStatus: '',
      USER_TYPE,
      ORG_USER_TYPE,
      planInfo: {},
      isShowFun: false, // 隐藏功能
      stuInfo: {},
      that: this,
      list: {},
      tips: '',
      examEnd: true,
      itemWidth: {width: '31%', marginBottom: '1%'},
      listHandles: [
        {
          label: '发布考试计划',
          type: '',
          classStyle: 'c-0067C0',
          handle: that => that.issueClick(that),
          isShow: () => this.list.examStatus !== examStatus.active.val,
        },
        {
          label: '编辑',
          type: '',
          classStyle: 'c-0067C0',
          handle: that => that.editsClick(that),
          isShow: () => this.list.examStatus !== examStatus.active.val,
        },
        {
          label: '删除',
          type: '',
          classStyle: 'c-F40606',
          handle: that => that.delClick(that),
          isShow: () => this.list.examStatus !== examStatus.active.val,
        },
      ],
      listConfig: [
        {
          prop: 'examSerial',
          placeholder: '考试计划编号',
        },
        {
          prop: 'examStartTime',
          placeholder: '考试开始时间',
        },
        {
          prop: 'examEndTime',
          placeholder: '考试结束时间',
        },
        {
          prop: 'examSessionAddr',
          placeholder: '考试地点',
        },
        {
          prop: 'paperTitle',
          placeholder: '考试试卷',
        },
      ],
      tableLoading: false,
      // 分配学员弹框
      allotStuDialogShow: false,
      // 审核学员资料弹框
      dialogShow: false,
      Title: '',
      // 学习详情弹框
      studyDetailsDialogShow: false,
      offlineStudyDetailsdialogShow: false,
      searchData: {
        stuName: '',
        trainningName: '',
        stuType: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          prop: 'stuName',
          placeholder: '请输入姓名',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'trainningName',
          placeholder: '请输入所属培训计划',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'stuType',
          placeholder: '请选择人员类别',
          options: _stuType,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '所属培训计划',
          prop: 'trainningName',
        },
        {
          label: '培训课程',
          prop: 'cateName',
        },
        {
          label: '人员类别',
          prop: 'stuType',
          formatter: row => Tools.formatDict(STU_TYPE, row.stuType),
        },
        {
          label: '姓名',
          prop: 'realName',
        },
        {
          label: '手机号码',
          prop: 'phone',
        },
        {
          label: '所属单位',
          prop: 'organization',
        },
        {
          label: '工作岗位',
          prop: 'job',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
        {
          label: '操作',
          type: 'button',
          width: '200px',
          btnList: [
            {
              label: '学习详情',
              type: 'text',
              handle: (that, row) => that.studyDetails(row),
            },
            {
              label: '移除',
              type: 'text',
              style: () => this.list.examStatus === examStatus.active.val ? '' : ({color: '#F40606'}),
              handle: (that, row) => that.removeClick(row),
              disabled: () => this.list.examStatus === examStatus.active.val,
            },
          ],
        },
      ],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          this.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          this.getTableData()
        },
      },
      userInfo: {},
    }
  },
  computed: {
    ...mapState('plan', {
      examSessionsId: state => state.examSessionsId,
      examSessionAddr: state => state.examSessionAddr,
    }),
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  created() {
    this.userInfo = getUserInfo()
    this.getPlanData()
    this.getTableData()
    // const selects = Tools.findSelect(['categoryChildIdList'], this.searchForm)
    // selects[0].options = this.categoryAllArr
  },
  methods: {
    // 提报按钮
    issueClick(row) {
      if (!this.tableData.length) {
        this.$message.error('该计划尚未添加考生')
        return
      }
      this.$confirm('是否发布考试计划', '发布考试计划', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const {success, msg} = await sessionsActive(this.examSessionsId)
        if (success) {
          this.$message.success('操作成功')
          await this.getTableData()
          await this.getPlanData()
        } else {
          const _msg = msg.split('<br/>')
          const _message = `${_msg.join(
            '<br/><br/>',
          )}`
          this.$message({
            // 将 message 就会被当作 HTML 片段处理
            dangerouslyUseHTMLString: true,
            message: _message,
            type: 'error',
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 审批计划弹窗
    Approval(row) {
      this.$prompt('如有需要，可输入备注', '审核', {
        inputType: 'textarea',
        confirmButtonText: '审核通过',
        cancelButtonText: '审核不通过',
        distinguishCancelAndClose: true, // 关闭弹窗回调与取消(审核不通过)回调分离
        beforeClose: (action, instance, done) => {
          // "确定"与"取消"事件
          if (action === 'cancel' || action === 'confirm') {
            const _data = {
              examSessionsId: this.examSessionsId,
              auditStatus: action === 'cancel' ? GovAuditStatus.fail.val : GovAuditStatus.pass.val,
              auditRemark: instance.inputValue || '',
            }
            this.approveEvent(_data)
          }
          // 关闭弹窗
          done()
        },
      }).then(() => {
      }).catch(() => {
      })
    },
    // 审批计划事件
    approveEvent(_data) {
      sessionsAudit(_data).then(res => {
        if (res.data) {
          this.$message.success('提交成功')
          // this.$emit('goListPage')
          this.goBack()
        }
      })
    },
    // 计划详情
    async getPlanData() {
      const {data} = await examListDetail(this.examSessionsId)
      this.list = data
      this.examEnd = moment(moment().format('YYYY-MM-DD HH:mm:ss')).isBefore(data.examEndTime)
      this.auditStatus = data.auditStatus
      if (data.auditStatus === GovAuditStatus.pass.val) {
        await this.getCheckExaminer()
      }
    },
    // 翻译字典
    fomatCategory(row) {
      const _names = Tools.listStrToName(row.categoryChildId, this.categoryAllMap)
      return _names.toString()
    },
    // 分配学员
    allotStuClick() {
      this.allotStuDialogShow = true
    },
    // 编辑计划
    editsClick() {
      this.planInfo = this.list
      this.Title = '编辑考试计划'
      this.dialogShow = true
    },
    // 获取表格数据
    async getTableData() {
      this.tableLoading = true
      const _searchData = JSON.parse(JSON.stringify(this.searchData))
      const query = {
        pageCondition: this.tablePage,
        bizCondition: _searchData,
      }
      query.bizCondition.examSessionsId = this.examSessionsId
      const {data} = await queryUnAssignStu(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 搜索按钮
    searchFormClick() {
      this.getTableData()
    },
    // 重置按钮
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 删除按钮
    delClick(row) {
      this.$confirm('是否删除考试计划', '删除', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const {success} = await sessionsDelete(this.examSessionsId)
        if (success) {
          this.$message.success('删除成功')
          this.goBack()
          await this.$parent.getTableData()
        } else {
          this.$message.warning('删除失败')
          await this.getTableData()
        }
      })
    },
    // 点击返回 返回计划列表页
    goBack() {
      // this.$emit('goListPage')
      this.$router.go(-1)
    },
    // 移除学员
    async removeClick(row) {
      const enrollExamIds = [row.enrollExamId]
      const data = await enrollExamUnBind(enrollExamIds)
      this.$message.success('操作成功')
      await this.getTableData()
    },
    // 学习详情弹框
    studyDetails(row) {
      this.stuInfo = row
      if (row.trainningType === 'online-study') {
        this.studyDetailsDialogShow = true
      } else {
        this.offlineStudyDetailsdialogShow = true
      }
    },
  },
}
</script>
<style scoped>
/deep/ .ces-handle {
  margin: 0;
  padding: 0;
}
</style>
