<template>
  <div class="warp_bg">
    <div class="flex flex-row justify-between">
      <div class="flex-1 c-white pl50">
        <div class="fs22 f-bold pb10 pt20">总体情况</div>
        <div class="flex flex-row align-center justify-between c-white pb20">
          <div
            v-for="(item,index_4) in overallSituation"
            :key="index_4"
            :class="index_4===overallSituation.length-1?'pr20':''"
          >
            <div class="flex flex-row justify-center lh1">
              <div class="fs40 f-bold text-center">{{ item.num }}</div>
            </div>
            <div class="c-B2B2B2 fs14 pt4 text-center">{{ item.txt }}({{ item.unit }})</div>
          </div>
        </div>
        <div style="position: relative">
          <div class="flex flex-row align-center justify-between mt20 mb20" style="width: 450px">
            <div class="fs22 f-bold">培训人员情况</div>
          </div>
          <div v-if="ageChartData.data" class="flex align-center">
            <loopChart
              id="loopCharts"
              :cdata="ageChartData"
              height="166px"
              width="220px"
            />
            <loopChart
              id="loopCharts"
              :cdata="sexChartData"
              class="ml20"
              height="166px"
              width="220px"
            />
          </div>
          <div v-else class="loading-all-wrap-mask" style="height: 200px;">
            <div class="flex justify-center align-center loading-all-wrap">
              <div class="loading-all-style loading-all-rotate" />
            </div>
          </div>
          <div v-if="!ageChartData.data" style="height: 200px;" />
        </div>

        <div class="" style="position: relative">
          <div class="flex flex-row align-center justify-between mt40" style="width: 460px">
            <div class="fs22 f-bold">三类人员培训完成情况</div>
            <div>
              <selectWeekMonthYear id="getJobTrainFinishList" @changeSelect="changeSelect" />
            </div>
          </div>
          <div
            v-if="CompletionRateOfPersonnelTraining.length"
            class="c-white"
            style="width: 460px; margin-top: 16px"
          >
            <div class="flex flex-row justify-between align-center bgc-3779FD pl8 pr8 text-center target-wrap ">
              <div class="flex-1 ">序号</div>
              <div class="flex-4">{{ isCompany() ? '企业' : '园区' }}</div>
              <div class="flex-2">参训率</div>
              <div class="flex-2">考核率</div>
            </div>
            <div style="height: 410px;overflow:auto; ">
              <div
                v-for="(item,index_2) in CompletionRateOfPersonnelTraining"
                :key="index_2"
                class="flex flex-row justify-between align-center text-center target-wrap bb-7B"
              >
                <div class="flex-1">{{ index_2 + 1 }}</div>
                <div class="flex-4 line-ellipsis-1">{{ item.orgName || '-' }}</div>
                <div class="flex-2">{{ item.tpRate || '-' }}</div>
                <div class="flex-2">{{ item.examRate || '-' }}</div>
              </div>
            </div>
          </div>
          <div v-else class="loading-all-wrap-mask" style="height: 500px;">
            <div class="flex justify-center align-center loading-all-wrap">
              <div class="loading-all-style loading-all-rotate" />
            </div>
          </div>
        </div>
      </div>
      <div style="width: 850px;height: 936px;">
        <div style="width: 850px;height: 690px;">
          <GaoDeMap
            ref="gaodemaps"
            height="690px"
            width="850px"
          />
        </div>
        <div class="fs22 f-bold c-white" style="margin-left: 15px">近半年园区化工培训计划趋势</div>
        <lineGradientChart
          id="lineGradients"
          :cdata="lineGradientData"
          height="233px"
          width="850px"
        />
      </div>
      <div class="flex-1 c-white pr50 ">
        <div class="flex flex-column align-end">
          <div style="position: relative">
            <div class="fs22 f-bold pb20 pt20" style="width: 460px">近半年园区各岗位培训情况</div>
            <lineChart
              v-if="chartLineData.data.length"
              id="lineCharts"
              :cdata="chartLineData"
              height="308px"
              width="460px"
            />
            <div v-else class="loading-all-wrap-mask" style="height: 200px;">
              <div class="flex justify-center align-center loading-all-wrap">
                <div class="loading-all-style loading-all-rotate" />
              </div>
            </div>
            <div v-if="!chartLineData.data.length" style="height: 200px;" />
          </div>
          <div class="fs22 f-bold mt40" style="width: 460px">近半年培训人数</div>
          <barChart id="barCharts" :cdata="chartBarData" height="208px" width="460px" />
          <div style="position: relative">
            <div class="flex flex-row align-center justify-between mt60" style="width: 460px">
              <div class="fs22 f-bold">三级安全培训等完成情况</div>
              <div>
                <selectWeekMonthYear id="getThirdSafetyTrain" @changeSelect="changeSelect" />
              </div>
            </div>
            <div
              v-if="thirdSafetyTrainList.length"
              class="c-white pt20"
              style="width: 460px;height: 176px;"
            >
              <div class="flex flex-row justify-between align-center bgc-3779FD pl8 pr8 text-center target-wrap-s ">
                <div class="flex-1 ">序号</div>
                <div class="flex-4">培训类别</div>
                <div class="flex-2">参训率</div>
                <div class="flex-2">考核率</div>
              </div>
              <div style="height: 132px;overflow:auto; ">
                <div
                  v-for="(item,index_2) in thirdSafetyTrainList"
                  :key="index_2"
                  class="flex flex-row justify-between align-center text-center target-wrap-s bb-7B"
                >
                  <div class="flex-1">{{ index_2 + 1 }}</div>
                  <div class="flex-4 line-ellipsis-1">{{ BIZLINE[item.trainingType].txt || '-' }}</div>
                  <div class="flex-2">{{ item.trainingRatio || '0' }}</div>
                  <div class="flex-2">{{ item.examRatio || '0%' }}</div>
                </div>
              </div>
            </div>
            <div v-else class="loading-all-wrap-mask" style="height: 200px;">
              <div class="flex justify-center align-center loading-all-wrap">
                <div class="loading-all-style loading-all-rotate" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import barChart from '@/views/components/newHomepageChart/barChart'
import lineChart from '@/views/components/newHomepageChart/lineChart'
import lineGradientChart from '@/views/components/newHomepageChart/lineGradientChart'
import loopChart from '@/views/components/newHomepageChart/loopChart'
import largeScreen from '@/styles/largeScreen.scss'
import selectWeekMonthYear from '@/views/components/selectWeekMonthYear'
import GaoDeMap from './components/map'
import { AgeLevel, ALL_TOTAL, BIZLINE, Gender, ORG_USER_TYPE, TIME_SELECTION, TRAINING_STATUS } from '@/const/sys'
import {
  jobTrainFinishList,
  jobTraining,
  overviewCnt,
  studentCnt,
  thirdSafetyTrain,
  trainingNum,
  trainningPlanTrend,
} from '@/api/common'
import { getUserInfo } from '@/utils/auth'

export default {
  name: 'Statistic',
  components: {
    loopChart,
    barChart,
    lineChart,
    selectWeekMonthYear,
    lineGradientChart,
    GaoDeMap,
  },
  data() {
    return {
      BIZLINE,
      sexChartData: {},
      thirdSafetyTrainList: [],
      CompletionRateOfPersonnelTraining: [],
      overallSituation: [],
      ageChartData: {},
      chartBarData: {
        color: '#3779FD',
        xAxisData: [],
        seriesData: [],
      },
      chartLineData: {
        xAxisData: [],
        data: [],
        yAxisData: [{
          name: '金额（元）',
        }],
      },
      lineGradientData: {
        xAxisData: [],
        data: [],
      },
    }
  },
  computed: {
    largeScreen() {
      return largeScreen
    },
  },
  created() {
    this.centerData()
    this.leftData()
    this.rightData()
  },
  methods: {
    centerData() {
      // 近半年安拆监测设备安装趋势
      this.getTrainningPlanTrend()
    },
    async getTrainningPlanTrend() {
      const res = await trainningPlanTrend(TIME_SELECTION.halfAYear.dateTime)
      for (const key in res.data) {
        this.lineGradientData.xAxisData.push(key)
        this.lineGradientData.data.push(res.data[key])
      }
    },
    rightData() {
      // 近半年园区各岗位培训情况
      this.getJobTraining()
      // 近半年培训人数
      this.getTrainingNum()
      // 三级安全培训等完成情况
      this.getThirdSafetyTrain(TIME_SELECTION.thisYear.dateTime)
    },
    async getJobTraining() {
      const res = await jobTraining()
      this.chartLineData.xAxisData = res.data.xAxisData
      this.chartLineData.data = res.data.seriesData
    },
    async getTrainingNum() {
      const res = await trainingNum()
      for (const key in TRAINING_STATUS) {
        this.chartBarData.xAxisData.push(TRAINING_STATUS[key].txt + ' ' + (res.data[key] ? res.data[key] : '0%'))
        this.chartBarData.seriesData.push(res.data[TRAINING_STATUS[key].cnt] || 0)
      }
    },
    async getThirdSafetyTrain(obj) {
      this.thirdSafetyTrainList = []
      const res = await thirdSafetyTrain(obj)
      this.thirdSafetyTrainList = res.data
    },
    leftData() {
      // 总体情况
      this.getOverviewCnt()
      //  培训人员情况
      this.getStudentCnt()
      // // 三类人员培训完成情况
      this.getJobTrainFinishList(TIME_SELECTION.thisYear.dateTime)
    },
    async getOverviewCnt() {
      const res = await overviewCnt()
      const _ALL_TOTAL = JSON.parse(JSON.stringify(ALL_TOTAL))
      const _isCompany = this.isCompany()
      if (_isCompany) {
        delete _ALL_TOTAL.parkCnt
      }
      const arr = Object.values(_ALL_TOTAL)
      for (const key in _ALL_TOTAL) {
        for (const i in arr) {
          if (arr[i].val === key) {
            arr[i].num = (res.data[key] > 10000) ? (res.data[key] / 10000).toFixed(1) : res.data[key]
            arr[i].unit = (res.data[key] > 10000) ? arr[i].maxUnit : arr[i].unit
          }
        }
      }
      this.overallSituation = arr
    },
    async getStudentCnt() {
      const res = await studentCnt()
      if (res.data.ageGroups) {
        const _data = res.data.ageGroups
        const _arr = []
        const _map = AgeLevel
        let _allNum = 0
        Object.keys(_map).forEach(item => {
          if (parseInt(_data[item])) {
            _allNum += parseInt(_data[item])
          }
          _arr.push({
            name: _map[item].txt + ' ',
            value: _data[item] || 0,
            itemStyle: { color: _map[item].color },
          })
        })
        _arr.forEach(item => {
          if (_allNum) {
            item.name = item.name + ((parseInt(item.value) / parseInt(_allNum)) * 100).toFixed(2) + '%'
          } else {
            item.name = item.name + '0%'
          }
        })
        this.ageChartData = {
          title: '按年龄统计人员',
          data: _arr,
          legendTop: '22%',
          legendRight: '0%',
        }
      }
      if (res.data.genderGroups) {
        const _data = res.data.genderGroups
        const _arr = []
        const _map = Gender
        let _allNum = 0
        Object.keys(_map).forEach(item => {
          if (parseInt(_data[item])) {
            _allNum += parseInt(_data[item])
          }
          _arr.push({
            name: _map[item].txt + ' ',
            value: _data[item] || 0,
            itemStyle: { color: _map[item].color },
          })
        })
        _arr.forEach(item => {
          if (_allNum) {
            item.name = item.name + ((parseInt(item.value) / parseInt(_allNum)) * 100).toFixed(2) + '%'
          } else {
            item.name = item.name + '0%'
          }
        })
        this.sexChartData = {
          title: '按性别统计人员',
          data: _arr,
          legendTop: '45%',
          seriesLeft: '25%',
          legendRight: '10%',
        }
      }
    },
    async getJobTrainFinishList(obj) {
      this.CompletionRateOfPersonnelTraining = []
      const res = await jobTrainFinishList(obj)
      const minLength = 10
      if (res.data.length < minLength) {
        const num = minLength - res.data.length
        for (let i = 1; i <= num; i++) {
          res.data.push('')
        }
      }
      this.CompletionRateOfPersonnelTraining = res.data
    },
    changeSelect(obj) {
      this[obj.id](obj.timeRange)
    },
    isCompany() {
      return getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_park_admin.val
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar-thumb:vertical { /*垂直滚动条的样式*/
  background-color: #3779FD;
}

::-webkit-scrollbar-track-piece {
  background-color: #000; /*滚动条的背景颜色*/
}

.early-warning-module {
  width: 900px;
  height: 445px;
  background: linear-gradient(rgba(0, 48, 121, 0.6) 50%, rgba(0, 48, 121, 0.06) 100%);
}

.projects-number {
  position: absolute;
  width: 100%;
}

.target-wrap {
  height: 40px
}

.target-wrap-s {
  height: 44px
}

.month-target {
  height: 42px
}

.warp_bg {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 964px;
  //background-image: url("../../../assets/images/country-bj.png");
  background-size: 100% 100%;
}
</style>
